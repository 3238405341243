import { Checkbox, Input, Textarea } from "@mui/joy";
import { Field } from "formik";
import usePalette from "../../theme/use_palette";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { formatDateToDateTimeLocal } from "../../utils/date_conversions";
import { useEffect, useRef, useState } from "react";
import "react-markdown-editor-lite/lib/index.css";
import StyledText from "../text/styled_text";
import { useSelector } from "react-redux";
import { selectAccentColor } from "../../redux/features/managerThemeSlice";
import { lightPaletteExported } from "../../theme/theme_context";
import { toast } from "react-toastify";
// Initialize a markdown parser
interface BaseFormInputProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  onChange?: any;
  overrideStyle?: Object;
  disabled?: boolean;
  /** Callback triggered on input focus */
  onFieldFocus?: (fieldName: string) => void;
  /** Callback triggered on input blur */
  onFieldBlur?: () => void;
}

interface FormInputProps extends BaseFormInputProps {
  type?: string;
  autoComplete?: string;
  readOnly?: boolean;
  clear?: () => void;
  afterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startDecorator?: any;
  endDecorator?: any;
  multiline?: boolean;
  allowNegative?: boolean;
}

export const DefaultInputStyle = {
  width: "200px",
  borderColor: lightPaletteExported.charcoalTransparent,
  backgroundColor: lightPaletteExported.white,
  borderWidth: "2px",
};

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  placeholder,
  type,
  multiline = false,
  onChange = undefined,
  readOnly = false,
  overrideStyle = {},
  autoComplete = "on",
  disabled = false,
  required = true,
  afterChange = () => {},
  startDecorator = null,
  endDecorator = null,
  onFieldFocus,
  onFieldBlur,
  allowNegative = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const accentColor = useSelector(selectAccentColor);
  const palette = usePalette();

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: any }) => (
        <Input
          {...field}
          onWheel={(event: any) => {
            if (type === "number") {
              event.preventDefault();
              event.target.blur();
            }
          }}
          onFocus={() => onFieldFocus && onFieldFocus(name)}
          onBlur={() => onFieldBlur && onFieldBlur()}
          ref={inputRef}
          label={label}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
          multiline={multiline}
          startDecorator={startDecorator}
          endDecorator={endDecorator}
          onChange={(e: any) => {
            try {
              if (type === "datetime-local") {
                if (!e.target.value) {
                  return;
                }
                try {
                  const dateValue = e.target.value;
                  if (dateValue && dateValue.includes("T")) {
                    const parsedDate = new Date(dateValue);
                    if (!isNaN(parsedDate.getTime())) {
                      const year = parsedDate.getFullYear();
                      const month = String(parsedDate.getMonth() + 1).padStart(
                        2,
                        "0"
                      );
                      const day = String(parsedDate.getDate()).padStart(2, "0");
                      const hours = String(parsedDate.getHours()).padStart(
                        2,
                        "0"
                      );
                      const minutes = String(parsedDate.getMinutes()).padStart(
                        2,
                        "0"
                      );
                      const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;

                      if (formatted !== e.target.value) {
                        e.target.value = formatted;
                      }
                    }
                  }
                } catch (dateError) {
                  toast.error("Invalid date format");
                  console.error("Error formatting datetime:", dateError);
                }
              }

              if (type === "number") {
                if (e.target.value.length > 1) {
                  e.target.value = e.target.value.replace(/^0+/, "");
                }

                // Handle negative numbers
                if (!allowNegative && e.target.value.startsWith("-")) {
                  e.target.value = e.target.value.replace("-", "");
                }
              }

              if (onChange) {
                onChange(e);
              } else {
                field.onChange(e);
              }
            } catch (error) {
              console.error("Error processing input change:", error);
            } finally {
              afterChange(e);
            }
          }}
          sx={{
            "--Input-focusedThickness": "0.1rem",
            transition: "all .15s ease-in-out",
            "&::before": {
              transition: "all .15s ease-in-out",
            },
            "&:focus-within::before": {
              boxShadow: "0 0 0 var(--Input-focusedThickness) " + accentColor,
            },
          }}
          placeholder={placeholder}
          type={type}
          style={
            {
              ...DefaultInputStyle,
              ...overrideStyle,
            } as any
          }
        />
      )}
    </Field>
  );
};

interface FormTextareaProps extends BaseFormInputProps {
  minRows?: number;
  maxChars?: number;
  maxRows?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  label,
  placeholder,
  minRows = 3,
  onChange = undefined,
  overrideStyle = {},
  required = true,
  maxChars = undefined, // Default max characters
  maxRows = 10,
  onKeyDown = undefined,
  onFieldFocus,
  onFieldBlur,
}) => {
  const [content, setContent] = useState<string>("");
  const palette = usePalette();
  const initializedRef = useRef(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    if (!maxChars || newText.length <= maxChars) {
      // Ensure the text does not exceed the max characters
      setContent(newText);
      if (onChange) {
        onChange(event as any);
      }
    }
  };

  return (
    <Field name={name}>
      {({ field }: any) => {
        // Safely sync field.value with content state once
        if (!initializedRef.current) {
          setContent(field.value || "");
          initializedRef.current = true;
        }

        // If field.value changes from outside and doesn't match content
        if (field.value !== content && field.value !== undefined) {
          setContent(field.value);
        }

        return (
          <div style={{ position: "relative", width: "97%" }}>
            <Textarea
              {...field}
              minRows={minRows}
              maxRows={maxRows}
              placeholder={placeholder}
              onChange={(event) => {
                handleChange(event);
                field.onChange(event);
                onChange && onChange(event as any);
              }}
              onFocus={() => onFieldFocus && onFieldFocus(name)}
              onBlur={() => onFieldBlur && onFieldBlur()}
              onKeyDown={onKeyDown}
              // Use field.value directly instead of managing content state
              value={content}
              required={required}
              style={{
                ...DefaultInputStyle,
                width: "100%",
                ...overrideStyle,
              }}
              endDecorator={
                maxChars && (
                  <StyledText
                    level="body-xs"
                    sx={{ ml: "auto" }}
                    color={
                      (content?.length || 0) >= maxChars
                        ? palette.errorMain
                        : palette.charcoalTransparent
                    }
                    fontSize={14}
                  >
                    {content?.length || 0}/{maxChars}
                  </StyledText>
                )
              }
            />
          </div>
        );
      }}
    </Field>
  );
};

interface FormMarkdownProps extends BaseFormInputProps {
  minRows?: number;
  maxChars?: number;
  onChange?: (content: string) => void;
}

export const FormMarkdown: React.FC<FormMarkdownProps> = ({
  name,
  label,
  placeholder,
  minRows = 2,
  onChange = () => {},
  onFieldFocus,
  onFieldBlur,
  overrideStyle = {},
  maxChars = 2000, // Default max characters
  required = false,
}) => {
  const [content, setContent] = useState<string>("");
  const palette = usePalette();
  const initializedRef = useRef(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    if (newText.length <= maxChars) {
      // Ensure the text does not exceed the max characters
      setContent(newText);
      if (onChange) {
        onChange(newText);
      }
    }
  };

  return (
    <Field name={name}>
      {({ field, form }: any) => {
        // Safely sync field.value with content state once
        if (!initializedRef.current) {
          setContent(field.value || "");
          initializedRef.current = true;
        }

        // If field.value changes from outside and doesn't match content
        if (field.value !== content && field.value !== undefined) {
          setContent(field.value);
        }

        return (
          <div style={{ position: "relative", width: "97%" }}>
            <Textarea
              {...field}
              minRows={minRows}
              placeholder={placeholder}
              onChange={(event) => {
                handleChange(event);
                field.onChange(event);
              }}
              onFocus={() => onFieldFocus && onFieldFocus(name)}
              onBlur={() => onFieldBlur && onFieldBlur()}
              required={required}
              value={content}
              style={{
                ...DefaultInputStyle,
                width: "100%",
                ...overrideStyle,
              }}
              endDecorator={
                <StyledText
                  level="body-xs"
                  sx={{ ml: "auto" }}
                  color={
                    (content?.length || 0) >= maxChars
                      ? palette.errorMain
                      : palette.charcoalTransparent
                  }
                  fontSize={14}
                >
                  {content?.length || 0}/{maxChars}
                </StyledText>
              }
            />
          </div>
        );
      }}
    </Field>
  );
};

interface FormCheckboxProps extends BaseFormInputProps {}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  onChange = undefined,
  onFieldFocus,
  onFieldBlur,
  disabled = false,
  required = false,
}) => (
  <Field name={name} type="checkbox" label={label}>
    {({ field }: any) => (
      <Checkbox
        {...field}
        disabled={disabled}
        onFocus={() => onFieldFocus && onFieldFocus(name)}
        onBlur={() => onFieldBlur && onFieldBlur()}
        value={field.value} // Ensure value is a string
        onChange={onChange ? onChange : field.onChange}
        required={required}
        sx={{
          border: "none",
        }}
        style={{
          ...DefaultInputStyle,
          backgroundColor: "transparent",
          width: "20px",
        }}
      />
    )}
  </Field>
);

export type PlaceOption = {
  label: string;
  value: Object;
};

interface FormGooglePlacesAutocompleteProps extends BaseFormInputProps {
  name: string;
  style?: any;
}

export const FormGooglePlacesAutocomplete: React.FC<
  FormGooglePlacesAutocompleteProps
> = ({ name, onFieldFocus, onFieldBlur, style }) => {
  const palette = usePalette();
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
            selectProps={{
              //@ts-ignore
              value: field.value, // Assuming field.value is a PlaceOption
              onChange: (option: PlaceOption | null) => {
                form.setFieldValue(name, option || undefined);
              },
              onFocus: () => onFieldFocus && onFieldFocus(name),
              onBlur: () => onFieldBlur && onFieldBlur(),
              styles: {
                control: (provided, state) => ({
                  ...provided,
                  ...DefaultInputStyle,
                  width: "300px",
                  ...style,
                  color: palette.black,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  background: `${palette.alwaysWhite}22`,
                  backdropFilter: "blur(8px)",
                  borderRadius: "8px",
                  color: palette.black,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: palette.black,
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "rgba(121, 201, 158, 0.7)"
                    : state.isFocused
                    ? `${palette.alwaysWhite}33`
                    : "transparent",
                  color: palette.black,
                }),
                // Style for the selected value text in the input
                singleValue: (provided) => ({
                  ...provided,
                  color: palette.black, // Color of selected text
                  fontWeight: 400,
                }),
                // Style for the input text
                input: (provided) => ({
                  ...provided,
                  color: palette.black,
                }),
              },
              menuPortalTarget: document.body,
              placeholder: "Location",
            }}
          />
        );
      }}
    </Field>
  );
};
