// Import statements should be at the top
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import React, {
  ComponentType,
  startTransition,
  Suspense,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { ROUTES } from "./def";
import ProtectedRoute, {
  SuperAdminProtectedRoute,
} from "../components/login/PrivateRoute";
import { currentUserRequest } from "../redux/features/userSlice";
import Logout from "../components/user/logout";
import TesseraWrapper from "../components/wrappers/page_wrapper";
import LoadingOverlay, { LoadingTop } from "../components/Loading";

import {
  MainPage,
  ProfilePage,
  EditEventAddTicketReleasePage,
  EditTicketTypes,
  CreateOrganizationPage,
  ManageEventPage,
  TicketScannerPage,
  EventEconomyPage,
  EditTicketReleaseAddonsPage,
  ManageEventTicketReleasesPage,
  ManageEventTicketsPage,
  ManageEventFormResponsesPage,
  FourOFour404,
  EventsPage,
  CreateEventPage,
  EditEventPage,
  HandleLoginCallback,
  ForgotPassword,
  PasswordReset,
  ExternalVerifyEmail,
  ContactPage,
  SendOut,
  PrivacyPolicy,
  EventDetail,
  SettingsFinancialPage,
  EventSendOutsPage,
  ManageSendOutList,
  EditEventTicketReleasesPage,
  EditEventFormPage,
  LoginPage,
  PricingPage,
  PostLoginPage,
  BecomeAManagerPage,
  ManagerPage,
  ManagerTeamsPage,
  EditLandingPageSettingsPage,
  EventDetailLandingPage,
  ManagerSetupPage,
  NetworkGeneralSettingsPage,
  NetworkUsersSettingsPage,
  AcceptNetworkInvitePage,
  ManagerFinancialSettingsPage,
  FAQPage,
  NetworkCustomizationSettingsPage,
  ProductTicketDistributionPage,
  ProductEventManagementPage,
  ProductEventPagesPage,
  ProductTeamManagementPage,
  ProductBETAProgramPage,
  ProductEventFormsPage,
  ProductPaymentsPage,
  ProductManagerOnboardingPage,
  ProductDataSecurityPage,
  ProductPage,
  DomainEventSettings,
  EventEmailTemplatesPage,
  NetworkSubscriptionSettingsPage,
  LandingPageEditorPage,
  NetworkContactDatabasePage,
  NetworkBusinessPage,
  UnsubscribePage,
  NetworkSocialMediaSettings,
  ViewOrganizationPage,
  NetworkAIAssistantPage,
  NetworkApiKeysSettingsPage,
  EventToolsSeatingPlan,
  LoginAlternativesPage,
  MealManagementPage,
  EmbedEventPage,
  SolutionsMainPage,
  InPersonEventsPage,
  UniversityEventsPage,
  ComplexTeamsPage,
  EasyEventSetupPage,
  AICreateEventPage,
  EventDraftPage,
  EventCreateSelectPage,
} from "./page_import";

import GuestTicketRequestPage from "../pages/event/guest/guest_ticket_request";
import AdminPage from "../admin";
import { useCanAccessEvent } from "../utils/event_access";
import ConsentPreferences from "../components/legal/consent_banner";
import EffortlessEventManagementPage from "../pages/product/event_management";
import { LEGAL_LINKS } from "../components/legal/legal_links";
import { EventProvider } from "../context/EventContext";
import SubdomainHandler from "./subdomain_handler";
import ManagerLayout from "./layouts/manager_layout";
import EventLayout from "./layouts/event_layout";
import EditTicketReleasesPage from "../pages/event/edit/edit_ticket_releases";
import ReturnPolicy from "../components/legal/return_policy";
import RouteChangeLoader from "../components/routing/route_change_loader";
import TermsAndConditions from "../components/legal/terms";

function withCurrentUserRequest<P>(
  Component: ComponentType<P>
): ComponentType<P & React.JSX.IntrinsicAttributes> {
  const WrappedComponent: React.FC<P & React.JSX.IntrinsicAttributes> = (
    props
  ) => {
    const dispatch = useDispatch();

    useEffect(() => {
      startTransition(() => {
        dispatch(currentUserRequest());
      });
    }, [dispatch]);

    return <Component {...props} />;
  };

  return WrappedComponent;
}

const MainPageWithCurrentUser = withCurrentUserRequest(MainPage);
const EventDetailWithCurrentUser = withCurrentUserRequest(EventDetail);
const ProfilePageWithCurrentUser = withCurrentUserRequest(ProfilePage);
const EventsPageWithCurrentUser = withCurrentUserRequest(EventsPage);
const CreateEventPageWithCurrentUser = withCurrentUserRequest(CreateEventPage);
const EditEventPageWithCurrentUser = withCurrentUserRequest(EditEventPage);
const EditEventAddTicketReleasePageWithCurrentUser = withCurrentUserRequest(
  EditEventAddTicketReleasePage
);
const EditTicketTypesWithCurrentUser = withCurrentUserRequest(EditTicketTypes);
const EditEventTicketReleasesPageWithCurrentUser = withCurrentUserRequest(
  EditEventTicketReleasesPage
);

const EditEventFormPageWithCurrentUser =
  withCurrentUserRequest(EditEventFormPage);

const CreateOrganizationPageWithCurrentUser = withCurrentUserRequest(
  CreateOrganizationPage
);
const ManageEventPageWithCurrentUser = withCurrentUserRequest(ManageEventPage);
const TicketScannerPageWithCurrentUser =
  withCurrentUserRequest(TicketScannerPage);
const EventEconomyPageWithCurrentUser =
  withCurrentUserRequest(EventEconomyPage);

const EditTicketReleaseAddonsWithCurrentUser = withCurrentUserRequest(
  EditTicketReleaseAddonsPage
);

const EditLandingPageSettingsPageWithCurrentUser = withCurrentUserRequest(
  EditLandingPageSettingsPage
);

const LandingPageEditorPageWithCurrentUser = withCurrentUserRequest(
  LandingPageEditorPage
);

const ManageEventTicketReleasesWithCurrentUser = withCurrentUserRequest(
  ManageEventTicketReleasesPage
);

const ManageEventTicketsWithCurrentUser = withCurrentUserRequest(
  ManageEventTicketsPage
);

const ManageEventFormResponsesWithCurrentUser = withCurrentUserRequest(
  ManageEventFormResponsesPage
);

const SettingsFinancialPageWithCurrentUser = withCurrentUserRequest(
  SettingsFinancialPage
);

const SettingsEventDomainPageWithCurrentUser =
  withCurrentUserRequest(DomainEventSettings);

const NetworkUsersPageWithCurrentUser = withCurrentUserRequest(
  NetworkUsersSettingsPage
);

const NetworkSocialMediaWithCurrentUser = withCurrentUserRequest(
  NetworkSocialMediaSettings
);

const ManageSendOutNewWithCurrentUser = withCurrentUserRequest(SendOut);
const ManageSendOutListWithCurrentUser =
  withCurrentUserRequest(ManageSendOutList);

const EventSendOutsPageWithCurrentUser =
  withCurrentUserRequest(EventSendOutsPage);

const PostLoginPageWithCurrentUser = withCurrentUserRequest(PostLoginPage);

const ManagerPageWithCurrentUser = withCurrentUserRequest(ManagerPage);

const ManagerSettingsPageWithCurrentUser =
  withCurrentUserRequest(ManagerSetupPage);

const NetworkGeneralSettingsWithCurrentUser = withCurrentUserRequest(
  NetworkGeneralSettingsPage
);

const NetworkCustomSettingsWithCurrentUser = withCurrentUserRequest(
  NetworkCustomizationSettingsPage
);

const EventEmailTemplatesPageWithCurrentUser = withCurrentUserRequest(
  EventEmailTemplatesPage
);

const NetworkSubscriptionSettingsPageWithCurrentUser = withCurrentUserRequest(
  NetworkSubscriptionSettingsPage
);

const NetworkContactDatabasePageWithCurrentUser = withCurrentUserRequest(
  NetworkContactDatabasePage
);

const ViewOrganizationPageWithCurrentUser =
  withCurrentUserRequest(ViewOrganizationPage);

const NetworkBusinessSettingsPageWithCurrentUser =
  withCurrentUserRequest(NetworkBusinessPage);

const NetworkAIAssistantPageWithCurrentUser = withCurrentUserRequest(
  NetworkAIAssistantPage
);

const NetworkApiKeysSettingsPageWithCurrentUser = withCurrentUserRequest(
  NetworkApiKeysSettingsPage
);

const EventToolsSeatingPlanPageWithCurrentUser = withCurrentUserRequest(
  EventToolsSeatingPlan
);

const EventToolsEmbedEventWithCurrentUser =
  withCurrentUserRequest(EmbedEventPage);

const EventToolsMealManagementPageWithCurrentUser =
  withCurrentUserRequest(MealManagementPage);

const AICreateEventPageWithCurrentUser =
  withCurrentUserRequest(AICreateEventPage);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const ProtectedEventRoutes: React.FC = () => {
  const { eventID } = useParams(); // Required for the eventID
  const navigate = useNavigate();

  const canAccess = useCanAccessEvent(eventID!);

  if (!canAccess) {
    navigate(-1);
    return null;
  } else {
    return <Outlet />;
  }
};

export const formatRoute = (route: string): string => {
  // If it starts with / remove it othwewise leave it
  return route.startsWith("/") ? route.slice(1) : route;
};

interface ExternalRedirectProps {
  to: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

function AppRoutes() {
  return (
    <EventProvider>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <RouteChangeLoader />

        <Suspense fallback={<LoadingTop />}>
          <Routes>
            <Route path="*" element={<SubdomainHandler />}>
              <Route
                path={formatRoute(ROUTES.HANDLE_LOGIN_CALLBACK)}
                element={<HandleLoginCallback />}
              />
              <Route path={formatRoute(ROUTES.LOGIN)} element={<LoginPage />} />
              <Route
                path={formatRoute(ROUTES.FORGOT_PASSWORD)}
                element={<ForgotPassword />}
              />
              <Route
                path={formatRoute(ROUTES.PASSWORD_RESET)}
                element={<PasswordReset />}
              />
              <Route path={formatRoute(ROUTES.FAQ)} element={<FAQPage />} />
              <Route
                path={formatRoute(ROUTES.EXTERNAL_VERIFY_EMAIL)}
                element={<ExternalVerifyEmail />}
              />

              {/* <Route
                path={formatRoute(ROUTES.LOGIN_ALTERNATIVES)}
                element={<LoginAlternativesPage />}
              /> */}

              {/* Contact subscribe/unsubscribe */}
              <Route
                path={formatRoute(ROUTES.UNSUBSCRIBE)}
                element={<UnsubscribePage />}
              />

              {/* Product */}
              <Route
                path={formatRoute(ROUTES.PRODUCT)}
                element={<ProductPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_TICKET_DISTRIBUTION)}
                element={<ProductTicketDistributionPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_EVENT_MANAGEMENT)}
                element={<ProductEventManagementPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_EVENT_PAGES)}
                element={<ProductEventPagesPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_TEAM_MANAGEMENT)}
                element={<ProductTeamManagementPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_BETA_PROGRAM)}
                element={<ProductBETAProgramPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_EVENT_FORMS)}
                element={<ProductEventFormsPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_PAYMENTS)}
                element={<ProductPaymentsPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_MANAGER_ONBOARDING)}
                element={<ProductManagerOnboardingPage />}
              />

              <Route
                path={formatRoute(ROUTES.PRODUCT_DATA_SECURITY)}
                element={<ProductDataSecurityPage />}
              />

              {/* Solutions */}
              <Route
                path={formatRoute(ROUTES.SOLUTIONS)}
                element={<SolutionsMainPage />}
              />
              <Route
                path={formatRoute(ROUTES.SOLUTIONS_IN_PERSON_EVENTS)}
                element={<InPersonEventsPage />}
              />
              <Route
                path={formatRoute(ROUTES.SOLUTIONS_UNIVERSITY_EVENTS)}
                element={<UniversityEventsPage />}
              />
              <Route
                path={formatRoute(ROUTES.SOLUTIONS_COMPLEX_TEAMS)}
                element={<ComplexTeamsPage />}
              />
              <Route
                path={formatRoute(ROUTES.SOLUTIONS_EASY_EVENT_SETUP)}
                element={<EasyEventSetupPage />}
              />

              <Route path={formatRoute(ROUTES.MAIN)} element={<MainPage />} />
              <Route
                path={formatRoute(ROUTES.PRICING)}
                element={<PricingPage />}
              />

              <Route
                path={formatRoute(ROUTES.EVENT_DETAIL)}
                element={<EventDetail />}
              />

              <Route
                path={formatRoute(ROUTES.EVENT_DETAIL_LANDING_PAGE)}
                element={<EventDetailLandingPage />}
              />

              <Route
                path={formatRoute(ROUTES.GUEST_TICKET_REQUEST)}
                element={<GuestTicketRequestPage />}
              />

              {/* Business network page */}
              <Route
                path={formatRoute(ROUTES.NETWORK_BUSINESS_PAGE)}
                element={<NetworkBusinessSettingsPageWithCurrentUser />}
              />

              <Route element={<ProtectedRoute />}>
                <Route
                  path={formatRoute(ROUTES.PROFILE)}
                  element={<ProfilePageWithCurrentUser />}
                />

                {/* <Route
                  path={formatRoute(ROUTES.CREATE_EVENT)}
                  element={<CreateEventPageWithCurrentUser />}
                />

                <Route
                  path={formatRoute(ROUTES.AI_CREATE_EVENT)}
                  element={<AICreateEventPageWithCurrentUser />}
                /> */}

                <Route
                  path={formatRoute(ROUTES.CREATE_TEAM)}
                  element={<CreateOrganizationPageWithCurrentUser />}
                />

                {/* Become and Manager */}
                <Route
                  path={formatRoute(ROUTES.POST_LOGIN)}
                  element={<PostLoginPageWithCurrentUser />}
                />

                <Route
                  path={formatRoute(ROUTES.BECOME_A_MANAGER)}
                  element={<BecomeAManagerPage />}
                />

                {/* Accept network invite */}
                <Route
                  path={formatRoute(ROUTES.ACCEPT_NETWORK_INVITE)}
                  element={<AcceptNetworkInvitePage />}
                />

                {/* Manager related routes */}
                <Route
                  path={formatRoute(ROUTES.MANAGER.BASE)}
                  element={<ManagerLayout />}
                >
                  {/* Default manager dashboard */}
                  <Route index element={<ManagerPageWithCurrentUser />} />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.DASHBOARD)}
                    element={<ManagerPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.TEAMS)}
                    element={<ManagerTeamsPage />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.TEAMS_VIEW)}
                    element={<ViewOrganizationPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.CONTACT_DATABASE)}
                    element={<NetworkContactDatabasePageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.SETUP)}
                    element={<ManagerSettingsPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGER.TESSERA_AI)}
                    element={<NetworkAIAssistantPageWithCurrentUser />}
                  />
                  <Route path={formatRoute(ROUTES.MANAGER.CREATE_EVENT.BASE)}>
                    <Route index element={<EventCreateSelectPage />} />
                    <Route
                      path={formatRoute(ROUTES.MANAGER.CREATE_EVENT.ADVANCED)}
                      element={<CreateEventPageWithCurrentUser />}
                    />
                    <Route
                      path={formatRoute(ROUTES.MANAGER.CREATE_EVENT.EASY)}
                      element={<EventDraftPage />}
                    />
                  </Route>
                  {/* Manager Settings */}
                  <Route path={formatRoute(ROUTES.MANAGER.SETTINGS.BASE)}>
                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.GENERAL)}
                      element={<NetworkGeneralSettingsWithCurrentUser />}
                    />
                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.SUBSCRIPTION)}
                      element={
                        <NetworkSubscriptionSettingsPageWithCurrentUser />
                      }
                    />
                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.USERS)}
                      element={<NetworkUsersPageWithCurrentUser />}
                    />

                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.CUSTOMIZATION)}
                      element={<NetworkCustomSettingsWithCurrentUser />}
                    />

                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.SOCIAL_MEDIA)}
                      element={<NetworkSocialMediaWithCurrentUser />}
                    />

                    <Route
                      path={formatRoute(ROUTES.MANAGER.SETTINGS.API_KEYS)}
                      element={<NetworkApiKeysSettingsPageWithCurrentUser />}
                    />
                  </Route>
                </Route>

                <Route element={<SuperAdminProtectedRoute />}>
                  <Route
                    path={formatRoute(ROUTES.EVENTS_ALL)}
                    element={<EventsPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.ADMIN)}
                    element={<AdminPage />}
                  />
                </Route>

                <Route
                  path={formatRoute(ROUTES.EDIT_EVENT_LANDING_PAGE_EDTIOR)}
                  element={<LandingPageEditorPageWithCurrentUser />}
                />

                {/* Event check route */}
                <Route path={""} element={<EventLayout />}>
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT)}
                    element={<EditEventPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT_ADD_TICKET_RELEASE)}
                    element={<EditEventAddTicketReleasePageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(
                      ROUTES.EDIT_EVENT_TICKET_RELEASE_TICKET_TYPES
                    )}
                    element={<EditTicketTypesWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT_TICKET_RELEASE_ADDONS)}
                    element={<EditTicketReleaseAddonsWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT_TICKET_RELEASES)}
                    element={<EditEventTicketReleasesPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT_FORM)}
                    element={<EditEventFormPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EDIT_EVENT_LANDING_PAGE_SETTINGS)}
                    element={<EditLandingPageSettingsPageWithCurrentUser />}
                  />

                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT)}
                    element={<ManageEventPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_TICKET_RELEASES)}
                    element={<ManageEventTicketReleasesWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_TICKETS)}
                    element={<ManageEventTicketsWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_RESPONSES)}
                    element={<ManageEventFormResponsesWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_SETTINGS_FINANCIAL)}
                    element={<SettingsFinancialPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_SETTINGS_DOMAIN)}
                    element={<SettingsEventDomainPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_SEND_OUT_NEW)}
                    element={<ManageSendOutNewWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_SEND_OUT_LIST)}
                    element={<ManageSendOutListWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_ECONOMY)}
                    element={<EventEconomyPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.TICKET_SCANNER)}
                    element={<TicketScannerPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_SEND_OUT_NEW)}
                    element={<EventSendOutsPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.MANAGE_EVENT_EMAIL_TEMPLATES)}
                    element={<EventEmailTemplatesPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EVENT_TOOL_SEATING_PLAN)}
                    element={<EventToolsSeatingPlanPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EVENT_TOOL_MEAL_MANAGEMENT)}
                    element={<EventToolsMealManagementPageWithCurrentUser />}
                  />
                  <Route
                    path={formatRoute(ROUTES.EVENT_TOOL_EMBED_EVENT)}
                    element={<EventToolsEmbedEventWithCurrentUser />}
                  />
                </Route>
              </Route>

              <Route path={formatRoute(ROUTES.LOGOUT)} element={<Logout />} />
              <Route
                path={formatRoute(ROUTES.CONTACT_PAGE)}
                element={<ContactPage />}
              />
            </Route>
            <Route path="legal">
              <Route path="return-policy" element={<ReturnPolicy />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
            </Route>
            <Route path="404" element={<FourOFour404 />} />
            <Route path="*" element={<FourOFour404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </EventProvider>
  );
}

export default AppRoutes;
