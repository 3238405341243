import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSubdomain } from "../utils/subdomain/subdomain_utils";
import FourOFour404 from "../pages/errors/404";
import { EventDetail } from "./page_import";
import { useDispatch, useSelector } from "react-redux";
import { currentUserRequest } from "../redux/features/userSlice";
import { AppDispatch, RootState } from "../store";
import EventLandingPage from "../pages/event/detail/detail_landing_page";
import { Box } from "@mui/joy";
import StyledText from "../components/text/styled_text";
import { useTranslation } from "react-i18next";
import { IRole } from "../types";
import usePalette from "../theme/use_palette";

const SubdomainHandler: React.FC = () => {
  const subdomain = getSubdomain();
  const dispatch: AppDispatch = useDispatch();
  const [hasLandingPage, setHasLandingPage] = React.useState<boolean>(false);
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const is404Page = location.pathname === "/404";
  const { t } = useTranslation();
  const palette = usePalette();
  useEffect(() => {
    if (subdomain && !is404Page) {
      // Only dispatch request if not on 404 page
      dispatch(currentUserRequest());
    }
  }, [subdomain, dispatch, is404Page]);

  const triggerLandingPage = () => {
    setHasLandingPage(true);
  };

  if (is404Page) {
    window.location.assign(process.env.REACT_APP_BASE_URL + "/404");
    return null;
  }

  if (subdomain) {
    if (
      currentUser &&
      currentUser?.roles.some((role: IRole) => role.name === "super_admin")
    ) {
      if (hasLandingPage) {
        return <EventLandingPage subdomain={subdomain} />;
      }

      return (
        <EventDetail
          subdomain={subdomain}
          triggerLandingPage={triggerLandingPage}
        />
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: 2,
          bgcolor: palette.offWhite,
        }}
      >
        <StyledText level="h2" fontSize={28} color={palette.black}>
          {t(
            "Subdomain event links are currently under maintenance. Please check back later."
          )}
        </StyledText>
        <StyledText color={palette.black}>
          Please use format <a href="#">tesseratickets.se/events/id</a>. If you
          need immediate assistance, please contact support.
        </StyledText>
      </Box>
    );
  }

  return <Outlet />;
};

export default SubdomainHandler;
