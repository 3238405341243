import { Box, Divider, FormControl, Link, Stack } from "@mui/joy";
import {
  Form,
  Formik,
  FormikContext,
  useFormik,
  useFormikContext,
} from "formik";
import { ICustomerSignupValues, ITicketRelease } from "../../types";
import { useTranslation } from "react-i18next";
import StyledButton from "../buttons/styled_button";
import { useEffect, useState } from "react";
import { createSignupValidationSchema } from "../../validation/user/customer_signup_validation";
import StyledText from "../text/styled_text";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../forms/form_labels";
import { FormCheckbox, FormInput } from "../forms/input_types";
import { StyledErrorMessage } from "../forms/messages";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LEGAL_LINKS } from "../legal/legal_links";
import usePalette from "../../theme/use_palette";
import { adjustTransparency } from "../../utils/manager/color";

interface SignupFormProps {
  accountIsRequired: boolean;
  onSignup: (values: ICustomerSignupValues) => void;
  initialValues: ICustomerSignupValues;
  validationSchema: any;
  includeIsSaved?: boolean;
}

const SignupForm: React.FC<SignupFormProps> = ({
  accountIsRequired,
  onSignup,
  initialValues,
  validationSchema,
  includeIsSaved,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const palette = usePalette();

  const { t } = useTranslation();
  const { isValid, values, errors } = useFormikContext<ICustomerSignupValues>();

  const [capsLockOn, setCapsLockOn] = useState<boolean>(false);

  useEffect(() => {
    const handleCapsLock = (event: KeyboardEvent) => {
      if (event instanceof KeyboardEvent) {
        if (event.getModifierState("CapsLock")) {
          setCapsLockOn(true);
        } else {
          setCapsLockOn(false);
        }
      }
    };

    window.addEventListener("keydown", handleCapsLock);
    window.addEventListener("keyup", handleCapsLock);

    return () => {
      window.removeEventListener("keydown", handleCapsLock);
      window.removeEventListener("keyup", handleCapsLock);
    };
  }, []);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSignup(values);
      }}
      style={{
        width: "100%",
      }}
    >
      <StyledText
        level="h4"
        color={palette.primaryDark}
        fontSize={20}
        fontWeight={600}
        sx={{
          mb: 2,
        }}
      >
        {t("event.ticket_release.request_process.continue")}
      </StyledText>
      <Stack spacing={4} direction={isScreenSmall ? "column" : "row"}>
        <FormControl>
          <StyledFormLabel fontSize={16}>
            {t("event.ticket_release.request_process.form.first_name")}
          </StyledFormLabel>
          <FormInput
            name="first_name"
            label="First Name"
            placeholder="First Name"
            required
          />
          <StyledErrorMessage name="first_name" />
        </FormControl>
        <FormControl>
          <StyledFormLabel fontSize={16}>
            {t("event.ticket_release.request_process.form.last_name")}
          </StyledFormLabel>
          <FormInput
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            required
          />
          <StyledErrorMessage name="last_name" />
        </FormControl>
      </Stack>
      <Stack spacing={4} direction={isScreenSmall ? "column" : "row"} mt={1}>
        <FormControl
          sx={{
            mt: 1,
          }}
        >
          <StyledFormLabel fontSize={16}>
            {t("event.ticket_release.request_process.form.email")}
          </StyledFormLabel>
          <FormInput name="email" label="Email" placeholder="Email" required />
          <StyledErrorMessage name="email" />
        </FormControl>
        <FormControl
          sx={{
            mt: 1,
          }}
        >
          <StyledFormLabel fontSize={16}>
            {t("event.ticket_release.request_process.form.phone_number")}
          </StyledFormLabel>
          <FormInput
            name="phone_number"
            label="Phone Number"
            placeholder="Phone Number"
            required={false}
          />
          <StyledErrorMessage name="phone_number" />
        </FormControl>
      </Stack>

      {includeIsSaved && (
        <FormControl
          sx={{
            mt: 1,
          }}
        >
          <StyledFormLabel fontSize={14}>
            {t("event.ticket_release.request_process.form.button_save_account")}
          </StyledFormLabel>
          <FormCheckbox
            name="is_saved"
            label="Save my details for future purchases"
            disabled={accountIsRequired}
          />
          <StyledErrorMessage name="is_saved" />
          <StyledFormLabelWithHelperText fontSize={12}>
            {t(
              "event.ticket_release.request_process.form.button_save_account_helperText"
            )}
          </StyledFormLabelWithHelperText>
        </FormControl>
      )}

      {values.is_saved && (
        <Box>
          <FormControl>
            <StyledFormLabel fontSize={16}>
              {t("event.ticket_release.request_process.form.password")}
            </StyledFormLabel>
            <FormInput
              name="password"
              label="Password"
              placeholder={capsLockOn ? "Caps Lock is on" : "Password"}
              type="password"
              required
            />
            <StyledErrorMessage name="password" />
          </FormControl>
          <FormControl>
            <StyledFormLabel fontSize={16}>
              {t("event.ticket_release.request_process.form.password_repeat")}
            </StyledFormLabel>
            <FormInput
              name="password_repeat"
              label="Password Repeat"
              placeholder={capsLockOn ? "Caps Lock is on" : "Password Repeat"}
              type="password"
              required
            />
            <StyledErrorMessage name="password_repeat" />
          </FormControl>
        </Box>
      )}

      {/* Terms and Conditions Checkbox */}
      <FormControl
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 1.5,
          backgroundColor: adjustTransparency(palette.offWhite, 0.6),
          p: 2,
          borderRadius: 2,
          border: `1px solid ${adjustTransparency(palette.greyLight, 0.3)}`,
          transition: "all 0.2s ease",
          "&:focus-within": {
            boxShadow: `0 0 0 2px ${adjustTransparency(
              palette.primaryMain,
              0.2
            )}`,
            borderColor: palette.primaryMain,
          },
        }}
      >
        <FormCheckbox name="accept_terms" label="" required />
        <Box sx={{ mt: 0 }}>
          <StyledText
            level="body-sm"
            fontSize={14}
            color={palette.charcoal}
            sx={{
              whiteSpace: "normal",
              textAlign: "left",
              lineHeight: 1.5,
            }}
          >
            {t("customer.accept_terms.first")}{" "}
            <Link
              href={LEGAL_LINKS.privacy_policy}
              target="_blank"
              rel="noopener"
              sx={{
                color: palette.primaryMain,
                textDecoration: "none",
                fontWeight: 600,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("customer.accept_terms.privacy_policy")}
            </Link>
            {", "}
            <Link
              href={LEGAL_LINKS.terms_and_conditions}
              target="_blank"
              rel="noopener"
              sx={{
                color: palette.primaryMain,
                textDecoration: "none",
                fontWeight: 600,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("customer.accept_terms.terms_of_use")}
            </Link>
            {", and "}
            <Link
              href={LEGAL_LINKS.acceptable_use_policy}
              target="_blank"
              rel="noopener"
              sx={{
                color: palette.primaryMain,
                textDecoration: "none",
                fontWeight: 600,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("customer.accept_terms.acceptable_use_policy")}
            </Link>
            .
          </StyledText>
        </Box>
        <StyledErrorMessage name="accept_terms" />
      </FormControl>

      <StyledButton
        type="submit"
        size="md"
        bgColor={palette.black}
        color={palette.white}
        disabled={!isValid}
        fs={16}
        sx={{
          mt: 2,
        }}
      >
        {values && values.is_saved
          ? t("event.ticket_release.request_process.form.button_sign_up")
          : t(
              "event.ticket_release.request_process.form.button_continue_as_guest"
            )}
      </StyledButton>
    </Form>
  );
};

interface CustomerSignupFormProps {
  accountIsRequired?: boolean;
  onSignup: (values: ICustomerSignupValues) => void;
  includeIsSaved?: boolean;
}

const CustomerSignupForm: React.FC<CustomerSignupFormProps> = ({
  onSignup,
  accountIsRequired = false,
  includeIsSaved = true,
}) => {
  const initialValues: ICustomerSignupValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    is_saved: accountIsRequired || !includeIsSaved,
    password: "",
    password_repeat: "",
    accept_terms: false,
  };

  const validationSchema = createSignupValidationSchema(accountIsRequired);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSignup(values);
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <FormikContext.Provider value={formik}>
        <SignupForm
          accountIsRequired={accountIsRequired}
          onSignup={onSignup}
          initialValues={initialValues}
          validationSchema={validationSchema}
          includeIsSaved={includeIsSaved}
        />
      </FormikContext.Provider>
    </Box>
  );
};

export default CustomerSignupForm;
