import { addHours, addWeeks, format } from "date-fns";
import { PlaceOption } from "../components/forms/input_types";
import {
  formatDateToDateTimeLocal,
  getDurationUnits,
  paymentDurationToString,
} from "../utils/date_conversions";
import { blue, green, grey, orange, red } from "@mui/material/colors";

enum NotificationMethod {
  EMAIL = "email",
}

enum CancellationPolicy {
  FULL_REFUND = "full_refund",
  NO_REFUND = "no_refund",
}

export interface ISQLNullTime {
  Time: string;
  Valid: boolean;
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface INavigationBarOptions {
  isDashboard?: boolean;
  isEventPage?: boolean;
  isBusinessPage?: boolean;
  showLogin?: boolean;
}
export interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  user: any | null;
  token: string | null;
  error: string | null;
  fetchUser: boolean;
  onLoginRedirect: string | null;
  customerSignupSuccess: boolean;
  customerLoginSuccess: boolean;
  guestCustomer: IGuestCustomer | null;
}

export interface IGuestCustomerForm {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
}

export interface IGuestCustomer {
  id: string;
  roles: IRole[];
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  request_token?: string;
  verified_email: boolean;

  ticket_order_id?: number;
  ticket_orders?: ITicketOrder[];
  food_preferences?: IUserFoodPreference;
}

export interface UserState {
  user: IUser | null;
  loading: boolean;
  error: string | null;
  deactivateInitiated: boolean;
}

export interface ListEventState {
  events: IEvent[];
  loading: boolean;
  error: {
    message: string;
    status: number;
  } | null;
}

export interface INetworkInvite {
  id: number;
  email: string;
  network_id: number;
  network: INetwork;
  user_id?: string;
  user: IUser;
  role: INetworkUserRole;
  token: string;
  expires_at: Date;
  accepted: boolean;
  accepted_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export type ErrorState = {
  status: number | null;
  message: string | null;
} | null;

export interface NetworkInviteState {
  loading: boolean;
  invites: INetworkInvite[];
  error: ErrorState;
}

export interface EventState {
  event: IEvent | null;
  loading: boolean;
  error: string | null;
  errorStatusCode: number | null;
  bannerUploadProgress: number;
}

export interface LoginRequestAction {
  type: "LOGIN_REQUEST";
  payload: LoginCredentials;
}

export interface LoginSuccessAction {
  type: "LOGIN_SUCCESS";
  payload: { token: string; userRole: string };
}

export interface LoginFailureAction {
  type: "LOGIN_FAILURE";
  payload: string;
}

// Differeny types of roles
export enum RoleType {
  SUPER_ADMIN = "super_admin",
  MANAGER = "manager",
  CUSTOMER_GUEST = "customer_guest",
  CUSTOMER = "customer",
}

export interface IFreeRegisterFormValues {
  name: string;
  referral_source: string;
  referral_source_specific: string;
  registration_type?: "organization" | "individual";
  // beta_key: string;
}

export interface IRole {
  id: number;
  name: string;
}

export interface ICustomerSignupValues {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;

  is_saved?: boolean;
  password?: string;
  password_repeat?: string;
  accept_terms: boolean;
}

export enum NetworkRoleType {
  NetworkSuperAdmin = "network_super_admin",
  NetworkAdmin = "network_admin",
  NetworkMember = "network_member",
}

export type FeatureGroupType =
  | "event_management"
  | "ticket_management"
  | "api_integration"
  | "support"
  | "landing_page"
  | "financial_management"
  | "email_management"
  | "other";

export type PaymentPlanType = "monthly" | "yearly" | "one_time" | "no_payment";

export enum PaymentPlanOption {
  Monthly = "monthly",
  Yearly = "yearly",
  OneTime = "one_time",
  NoPayment = "no_payment",
}

export type PackageTierType = "free" | "professional" | "business";

export interface IFeatureGroup {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: FeatureGroupType;
  description: string;
}

export interface IPackageTier {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  tier: PackageTierType;
  description: string;
  standard_monthly_price: number;
  standard_yearly_price: number;
  plan_enrollments: IPlanEnrollment[];
  default_feature_ids: number[];
  default_features: IFeature[];
}

export interface IRequiredPlanFeatures {
  feature_name: string;
  plans: PackageTierType[];
}

export interface IPlanEnrollment {
  id: number;
  reference_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  creator_email: string;
  creator_id: string;
  creator: IUser; // You would need to define a User interface
  network_id: number;
  package_tier_id: number;
  features: IFeature[];
  monthly_price: number;
  yearly_price: number;
  one_time_price: number;
  plan: PaymentPlanType;
  features_usages: IFeatureUsage[];
  required_plan_features: IRequiredPlanFeatures[];
}

export interface IFeatureUsage {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  feature_id: number;
  plan_enrollment_id: number;
  usage: number;
  object_reference?: string;
}

export interface IFeature {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  description: string;
  feature_group_id: number;
  feature_group: IFeatureGroup;
  is_available: boolean;
  package_tiers: IPackageTier[];
  package_tiers_ids: number[];
  feature_limits: IFeatureLimit[];
  has_limit_access?: boolean;
}

export interface IFeatureLimit {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  feature_id: number;
  package_tier_id: number;
  limit_description: string;
  limit: number | null; // This is a hard limit
  monthly_limit: number | null;
  yearly_limit: number | null;
}

export type INetworkType = "individual" | "organization";

export enum NetworkType {
  Individual = "individual",
  Organization = "organization",
}

export interface INetwork {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  plan_enrollment_id: number;
  plan_enrollment: IPlanEnrollment;
  network_user_roles: INetworkUserRole[];
  users: IUser[]; // You would need to define a User interface
  organizations: IOrganization[]; // You would need to define an Organization interface
  merchant: INetworkMerchant;
  details: INetworkDetails;
  settings: INetworkSettings;
  social_media?: NetworkSocialMedia;
  auth_providers: INetworkAuthProvider;
  type: INetworkType;
}

export interface NetworkSocialMedia {
  network_id: number;
  facebook_link?: string;
  instagram_link?: string;
  twitter_link?: string;
  linkedin_link?: string;
  youtube_link?: string;
  tiktok_link?: string;
  website_link?: string;
}

export interface INetworkRole {
  id: number;
  name: NetworkRoleType;
  description: string;
  hierarchy: number;
}

export interface IBookDemoValues {
  name: string;
  email: string;
  message: string;
}

export interface INetworkSettings {
  id: number;
  network_id: number;
  main_color: string;
  accent_color: string;
  background_color: string;
  text_color: string;
  logo_url: string;
  icon_url: string;
  banner_url: string;
  description: string;
  short_description: string;
  created_at: Date;
  updated_at: Date | null;
}

// NetworkSettingsInput represents the input for creating or updating network settings
export interface INetworkSettingsInput {
  main_color: string;
  accent_color: string;
  background_color: string;
  text_color: string;
  banner_url: string;
  logo?: File | null;
  description: string;
  short_description: string;
}

export interface ICustomerLoginValues {
  email: string;
  password: string;
}

export interface ICustomer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  verifiedEmail: boolean;
  food_preferences?: IUserFoodPreference;
}

export interface IOrganizationUserRole {
  id: string;
  user_id: string;
  organization_id: number;
  organization_role_name: string;
  created_at: Date;
  updated_at: Date;
}

export interface INetworkUserRole {
  id: string;
  user_id: string;
  network_id: number;
  network_role_name: string;
  created_at: Date;
  updated_at: Date;
}

export type NetworkUserRoleType =
  | "network_super_admin"
  | "network_admin"
  | "network_member";

export interface IUser {
  // Define user properties based on your backend response
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  roles?: IRole[];
  organizations?: IOrganization[];
  food_preferences?: IUserFoodPreference;
  showed_post_login?: boolean;
  network_user_role?: INetworkUserRole;
  organization_user_roles?: IOrganizationUserRole[];
  is_guest?: boolean;
  deactivated_at?: Date | null;
  deletion_scheduled_at?: Date | null;
}

export interface IOrganizationUser extends IUser {
  organization_role: string;
  added_at: number;
}

export interface IOrganization {
  id: number;
  name: string;
  email: string;

  created_at?: number;
  organization_user_roles?: IOrganizationUserRole[];
  common_event_locations: {
    name: string;
  }[];
  users?: IUser[];
  details?: IOrganizationDetails;
}

export interface IOrganizationDetails {
  id: number;
  organization_id: number;

  brand_color: string;
  background_color: string;
  accent_color: string;
  logo_url: string;
  icon_url: string;

  description: string;
  short_description: string;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

export interface IOrganizationStoreCustomization {
  background_color: string;
  brand_color: string;
  accent_color: string;
  rect_shape: string;
  font_type: string;
  logo_url?: string;
  icon_url?: string;
  logo_file?: File | null;
  icon_file?: File | null;
}

export interface IMerchantCustomization {
  backgroundColor?: string;
  brandColor?: string;
  accentColor?: string;
  rectShape?: string;
  fontType?: string;
  logoUrl?: string;
  iconUrl?: string;
}

export interface IStoreTerminal {
  terminal_id: string;
  event_id: number;
  store_id: string;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

export interface IStore {
  store_id: string;
  name: string;
  organization_id: number;
  terminals: IStoreTerminal[];

  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

export interface IEventDetails {
  event_id: number;
  vat?: number;
  fee_transaction_plan: string;
  user_pays_ticket_fee: boolean;
  slug?: string;
  language?: string;
  banner_url?: string;
  seating_plan?: any;
}

export interface IEvent {
  id: number;
  reference_id: string;
  created_at: number;
  name: string;
  description: string;
  date: Date;
  end_date?: Date;
  location: string;
  organization_id: number;
  organization?: IOrganization;
  is_private: boolean;
  ticket_releases?: ITicketRelease[];
  created_by?: string;
  form_field_description?: string;
  form_fields?: IEventFormField[];
  landing_page?: IEventLandingPage;
  collect_food_preferences?: boolean;
  display_attendee_count?: boolean;
  terminal: IStoreTerminal;
  details?: IEventDetails;
  category: IEventCategory;
  interest_count?: number;
  event_meals: IEventMeal[];
  allowed_domains: IAllowedDomain[];
  tags: string[];
}

export enum EventCategoryType {
  Event = "event",
  Workshop = "workshop",
  Lecture = "lecture",
  Party = "party",
  Conference = "conference",
  Concert = "concert",
  Other = "other",
}

// Derive the union type from the enum
export type IEventCategory = `${EventCategoryType}`;

export interface IEventForm {
  name: string;
  description: string;
  date: string;
  end_date?: string;
  location: PlaceOption | null;
  organization_id: number;
  is_private: boolean;
  collect_food_preferences: boolean;
  display_attendee_count: boolean;
  language?: string;
  banner_url?: string;
  category?: IEventCategory | null;
  tags: string[];
}

export const SUPPORTED_LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Swedish",
    value: "se",
  },
];

export interface IEventPostReq {
  name: string;
  description: string;
  date: string;
  end_date?: string;
  location: string;
  organization_id: number;
  is_private: boolean;
  collect_food_preferences: boolean;
  display_attendee_count: boolean;
  language?: string;
  category: IEventCategory;
  tags?: string[];
}

export const EventFormInitialValues: IEventForm = {
  name: "",
  description: "",
  date: "",
  location: null,
  organization_id: 1,
  is_private: false,
  collect_food_preferences: false,
  display_attendee_count: false,
  language: "en",
  category: null,
  tags: [],
};

export const EventFormInitialTestValues: IEventForm = {
  name: "Test Event",
  description: "Test Event Description",
  date: formatDateToDateTimeLocal(new Date()),
  location: {
    label: "Test Location",
    value: {
      lat: 59.347,
      lng: 18.073,
    },
  },
  organization_id: 1,
  is_private: false,
  collect_food_preferences: false,
  display_attendee_count: false,
  category: EventCategoryType.Event,
  tags: [],
};

export interface ITicketReleaseMethod {
  id: number;
  name: string;
  descriptive_name: string;
  description: string;
}

export interface ITicketReleaseForm {
  event_date?: string;
  name: string;
  description: string;
  open: string;
  close: string;
  ticket_release_method_id: number;
  ticket_release_method_name: string;
  open_window_duration?: number;
  method_description?: string;
  max_tickets_per_user: number;
  notification_method: string;
  cancellation_policy: string;
  is_reserved: boolean;
  promo_code?: string;
  tickets_available: number;
  is_saved?: boolean;
  save_template: boolean;
  payment_deadline: string;
  reserve_payment_duration: string;
  allocation_cut_off: string;
  button_text: string;
}

export interface ITicketReleasePostReq {
  event_id?: number;
  name: string;
  description: string;
  open: string;
  close: string;
  open_window_duration?: number;
  max_tickets_per_user: number;
  notification_method: string;
  cancellation_policy: string;
  ticket_release_method_id: number;
  ticket_release_method_name?: string;
  is_reserved: boolean;
  promo_code?: string;
  tickets_available: number;
  method_description?: string;
  save_template: boolean;
  payment_deadline?: string;
  reserve_payment_duration?: string;
  allocation_cut_off?: string;
  button_text: string;
}

export const TicketReleaseFormInitialValues: ITicketReleaseForm = {
  name: "Tickets",
  description: "",
  open: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
  close: format(addWeeks(addHours(new Date(), 1), 1), "yyyy-MM-dd'T'HH:mm"),
  ticket_release_method_id: 5,
  ticket_release_method_name: "fcfs",
  open_window_duration: 0,
  method_description: "",
  max_tickets_per_user: 1,
  notification_method: "EMAIL",
  cancellation_policy: "FULL_REFUND",
  tickets_available: 50,
  is_reserved: false,
  promo_code: "",
  is_saved: false,
  save_template: false,
  payment_deadline: "",
  reserve_payment_duration: "",
  allocation_cut_off: "",
  button_text: "Buy Tickets",
};

export interface ITicketTypeForm {
  id?: number;
  name: string;
  description: string;
  price: number;
  save_template: boolean;
}

export interface ITicketTypePostReq {
  id?: number;
  event_id?: number;
  ticket_release_id?: number;
  name: string;
  description: string;
  price: number;
  save_template: boolean;
}

export const TicketTypeFormInitialValues: ITicketTypeForm = {
  name: "",
  description: "",
  price: 0,
  save_template: false,
};

export interface ITicketReleaseMethodDetail {
  id: number;
  name: string;
  max_tickets_per_user: number;
  cancellation_policy: ICancellationPolicy;
  open_window_duration: number | null; // Todo change
  method_description: string;
  notification_method: string;
  ticket_release_method?: ITicketReleaseMethod;
}

export type ICancellationPolicy = "FULL_REFUND" | "NO_REFUND";

export enum ITicketReleaseMethodType {
  FCFS_LOTTERY = "fcfs_lottery",
  PAY_DIRECT = "pay_direct",
  RESERVED_TICKET_RELEASE = "reserved_ticket_release",
  FCFS = "fcfs",
  SELECTIVE = "selective",
}

export enum NotificationType {
  EMAIL = "email",
}

export enum NotificationStatus {
  PENDING = "pending",
  SENT = "sent",
  FAILED = "failed",
}

export interface INotification {
  id: number;
  created_at: Date;
  updated_at: Date;
  event_id?: number | null;
  email: string;
  type: NotificationType;
  status: NotificationStatus;
  status_message?: string | null;
  send_out_id?: number | null;
  subject?: string | null;
  content?: string | null;
}
export interface ISendOut {
  id: number;
  event_id: number;
  notifications: INotification[];
  subject: string;
  content: string;
  created_at: Date;
  updated_at: Date;
}

export interface ITicketType {
  id: number;
  ticket_release_id: number;
  name: string;
  event_id: number;
  description?: string;
  price: number;
  quantityTotal: number;
  isReserved: boolean;
  save_template: boolean;
}

export interface ITicketRelease {
  created_at: string | number | Date;
  updated_at?: string | number | Date;
  id: number;
  event_id: number;
  name: string;
  description: string;
  open: Date;
  close: Date;
  has_allocated_tickets?: boolean;
  ticket_release_method_detail_id?: number;
  ticket_types?: ITicketType[];
  ticket_release_method_detail: ITicketReleaseMethodDetail;
  is_reserved?: boolean;
  promo_code?: string;
  event?: IEvent;
  tickets_available: number;
  add_ons?: IAddon[];
  payment_deadline: ITicketReleasePaymentDeadline;
  save_template: boolean;
  allocation_cut_off: Date;
  sold_out: boolean;
  button_text: string;
}

export interface ITicketReleasePaymentDeadline {
  id: number;
  ticket_release_id: number;
  original_deadline: Date;
  reserve_payment_duration: number;
}

export interface PromoCodeAccessForm {
  promo_code: string;
}

export const PromoCodeAccessFormInitialValues: PromoCodeAccessForm = {
  promo_code: "",
};

export interface ITicketReleaseAdmin extends ITicketRelease {
  id: number;
  ticket_release_method_detail_id: number;
  hasAllocatedTickets: boolean;
}

export interface IEventSalesReport {
  id: number;
  event_id: number;
  total_sales: number;
  tickets_sold: number;
  status: string;
  message: string;
  created_at: Date;
  updated_at: Date;
  url: string;
}

export interface ITicketOrder {
  id: number;
  user_id: string;
  user?: IUser;

  is_handled: boolean;
  num_tickets: number;
  total_amount: number;
  tax_amount: number;
  fee_amount: number;
  subtotal_amount: number;
  is_paid: boolean;
  is_refunded: boolean;
  paid_at: Date | null;
  type: string;

  ticket_release_id: number;
  ticket_release?: ITicketRelease;

  tickets: ITicket[];

  created_at: number;
  updated_at: number;
  deleted_at: number | null;
  deleted_reason?: string;

  attendee_details_gdpr: boolean;

  order_id: string;
  order: IOrder;
}

export interface IDeadlineUnits {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface ITicketReleasePaymentDeadlineForm {
  payment_deadline: string;
  reserve_payment_duration: string;
}

export interface ITicketBasket {
  id: number; // The ticket type ID
  name: string; // The name of the ticket
  quantity: number; // Number of tickets
  price: number; // The price of the ticket
  type: "ticket"; // Specifies that this is a ticket item
  addons: ISelectedAddon[]; // Addons specific to this ticket
}

export enum TicketStatus {
  PENDING = "Pending",
  CANCELLED_TICKET = "Cancelled Ticket",
  CANCELLED_REQUEST = "Cancelled Request",
  REFUNDED = "Refunded",
  CHECKED_IN = "Checked In",
  PAID = "Paid",
  RESERVED = "Reserved",
  PURCHASEABLE = "Ready for Purchase",
  EXPIRED = "Expired",
  LOTTERY_ENTERED = "Lottery Entered",
  HANDLED = "Handled",
}

export interface ITicket {
  id: number;
  ticket_order_id: number;
  ticket_order: ITicketOrder;
  is_reserve: boolean;
  refunded: boolean;
  reserve_number?: number;
  qr_code: string;
  was_reserve: boolean;

  checked_in: boolean;
  checked_in_at: Date | null;

  ticket_type: ITicketType;
  ticket_cost: number;
  total_cost: number;
  addons_cost: number;

  user_id: string;
  user: IUser;
  status: string;

  purchasable_at: ISQLNullTime;

  ticket_add_ons?: ITicketAddon[];
  ticket_type_id: number;
  payment_deadline: ISQLNullTime;
  event_form_responses?: IEventFormFieldResponse[];

  google_wallet_url?: string;
  apple_wallet_url?: string;

  created_at: Date;
  updated_at: Date | null;
  deleted_at: Date | null;
  deleted_reason?: string;

  ticket_attendee?: ITicketAttendee;
  has_saved_attendee_details: boolean;
}

export interface TicketRequestPostReq {
  ticket_type_id: number;
  ticket_release_id: number;
}

export interface CompleteEventWorkflowPostReq {
  event: IEventPostReq;
  ticket_release: ITicketReleasePostReq;
  ticket_types: ITicketTypePostReq[];
}

export interface CompleteTicketReleaseWorkflowPostReq {
  ticket_release: ITicketReleasePostReq;
  ticket_types: ITicketTypePostReq[];
}

export interface IFoodPreference {
  id: string;
  label: string;
  checked?: boolean;
}

export interface IEventSiteVisit {
  total_site_visits: number;
  total_site_visits_last_week: number;
  unique_visitors: number;
  unique_visitors_last_week: number;
  last_week_date: Date;
  num_ticket_orders: number;
  num_ticket_orders_last_week: number;
  total_income: number;
  total_income_last_week: number;
  site_visits_last_7_days: number[];
  unique_visitors_last_7_days: number[];
  num_ticket_orders_last_7_days: number[];
  total_income_last_7_days: number[];
}

export interface IUserFoodPreference {
  gluten_intolerant: boolean;
  lactose_intolerant: boolean;
  vegetarian: boolean;
  vegan: boolean;
  nut_allergy: boolean;
  egg_allergy: boolean;
  soy_allergy: boolean;
  shellfish_allergy: boolean;
  kosher: boolean;
  halal: boolean;
  prefer_meat: boolean;
  other: boolean;
  additional_info: string;
}

// export interface ITransaction {
//   id: number;
//   ticket_id: number;
//   amount: number;
//   currency: string;
//   payed_at: number;
//   refunded: boolean;
//   refunded_at: number | null;
//   payment_method?: string;
//   transaction_type: string;
// }

enum OrderStatusType {
  Pending = "pending",
  Initiated = "payment_initiated",
  Processed = "payment_processed",
  PaymentCompleted = "payment_completed",
  PaymentCancelled = "payment_cancelled",
  PartialPaymentCompleted = "partial_payment_completed",
}

export interface IOrder {
  id: number; // Automatically managed by GORM
  orderId: string;
  merchant_id: string;
  event_id: number;
  user_id: string;
  payment_page_link: string;

  status: OrderStatusType;
  details: IOrderDetails;
  tickets: ITicket[];

  created_at: Date;
  updated_at?: Date | null;
  deleted_at?: Date | null;
}

export interface IOrderDetails {
  id: number; // Automatically managed by GORM
  order_id: string;

  payment_id: string;
  transaction_id: string;
  payment_method: string;
  payment_status: string;
  truncated_pan: string;
  card_label: string;
  pos_entry_mode: string;
  issuer_application: string;
  terminal_verification_result: string;
  aid: string;
  customer_response_code: string;
  cvm_method: string;
  auth_mode: string;

  total: number;
  currency: string;

  refunded: boolean;
  refunded_at: Date | null;
  payed_at: string | null;

  created_at: Date;
  updated_at?: Date | null;
  deleted_at?: Date | null;
}

export interface IEventFinancialDetails {
  id: number;
  vat: number;
  user_pays_ticket_fee: boolean;
  created_at: Date;
}

export interface IEventFinancialDetailsPostReq {
  vat: number;
  user_pays_ticket_fee: boolean;
}

export interface IEventDomainSettingsPostReq {
  slug: string;
}

// External user
export interface ISignupFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_repeat: string;
}

export interface ILoginFormValues {
  email: string;
  password: string;
}

export const SignupInitialValues: ISignupFormValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_repeat: "",
};

export const LoginInitialValues: ILoginFormValues = {
  email: "",
  password: "",
};

export type IEventFormFields =
  | "text"
  | "number"
  | "checkbox"
  | "select"
  | "multiselect"
  | "image"
  | "";
export const EventFormFieldsArray: IEventFormFields[] = [
  "text",
  "number",
  "checkbox",
  "select",
  "multiselect",
  "image",
  "",
];

export const EventFormFields: { id: IEventFormFields; label: string }[] = [
  {
    id: "text",
    label: "Text",
  },
  {
    id: "number",
    label: "Number",
  },
  {
    id: "checkbox",
    label: "Checkbox",
  },
  {
    id: "select",
    label: "Select",
  },
  {
    id: "multiselect",
    label: "Multiselect",
  },
  {
    id: "image",
    label: "Image",
  },
];

// src/types/index.ts

export interface IEventFormFieldResponse {
  id: number;
  ticket_order_id: number;
  event_form_field_id: number;
  event_form_field?: IEventFormField;
  value: string | number | boolean | null;
  updated_at: number;
}

export interface IEventFormField {
  id: number;
  name: string;
  description: string;
  is_required: boolean;
  type: IEventFormFields;
  event_id: number;
  options?: string[];
  order: number;
}

export interface IEventFormFieldInput {
  form_field_description: string;
  form_fields: IFormFieldInput[];
}

export interface IFormFieldInput {
  name: string;
  description: string;
  is_required: boolean;
  type: string;
  options?: string[];
  order: number;
}

// Define additional types as needed
export interface FormFieldsState {
  formFields: IEventFormField[]; // Assuming you want to store an array of form fields
  loading: boolean;
  error: string | null;
}

export interface IContactFormValues {
  name: string;
  email: string;
  organization_id: number | null;
  subject: string;
  message: string;
}

export interface IAddon {
  id: number;
  name: string;
  description: string;
  price: number;
  contains_alcohol: boolean;
  max_quantity: number;
  is_enabled: boolean;
  ticket_release_id?: number;
}

export interface ITicketAddon {
  id: any;
  add_on_id: number;
  add_on?: IAddon;
  ticket_id?: number;
  quantity: number;
}

export interface ISelectedAddon {
  id: number;
  quantity: number;
}

export interface IEventLandingPage {
  id?: number;
  event_id: number;
  html: string;
  css: string;
  js: string;
  enabled: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export enum SurfApplicationStatus {
  ApplicationNotStarted = "application_not_started",
  ApplicationInitiated = "application_initiated",
  ApplicationSubmitted = "application_submitted",
  ApplicationPendingInformation = "application_pending_information",
  ApplicationSigned = "application_signed",
  ApplicationRejected = "application_rejected",
  ApplicationCompleted = "application_completed",
  ApplicationExpired = "application_expired",
  MerchantCreated = "merchant_created",
}

export const ApplicationStatusColors = {
  application_not_started: grey[500],
  application_initiated: green[200],
  application_submitted: green[400],
  application_pending_information: orange[500],
  application_signed: blue[500],
  application_rejected: red[500],
  application_completed: green[500],
  application_expired: red[500],
  merchant_created: green[500],
};

export interface INetworkMerchant {
  id: number;
  created_at: Date;
  updated_at: Date;
  network_id: number;
  application_id: string;
  merchant_id: string;
  application_status: SurfApplicationStatus;
  web_kyb_url: string;
  store_id: string;
  fee_transaction_plan: string;
}

export interface INetworkDetails {
  id: number;
  created_at: Date;
  updated_at: Date;
  network_id: number;
  corporate_id: string; // Corporate ID of the network
  legal_name: string; // Legal name of the network
  description: string; // Description of the network
  care_of: string; // Care of (c/o) of the network
  address_line1: string; // Address line 1 of the network
  address_line2: string; // Address line 2 of the network
  language: string;
  city: string;
  postal_code: string;
  country: string;
  phone_code: string;
  phone_number: string;
  country_code: string; // Two-letter ISO country code, in uppercase. i.e 'SE' | 'DK' | 'FI'.
  email: string; // main email for the network
  network_slug: string;
}

export type Country = {
  name: string;
  code: string;
  phone: number;
};

export const AvaialableCountries: Country[] = [
  {
    name: "Sweden",
    code: "SE",
    phone: 46,
  },
];

export interface BusinessDetailsFormValues {
  country_code: string;
  legal_name: string;
  corporate_id: string;
  address_line1: string;
  address_line2: string;
  city: string;
  postal_code: string;
  phone_number: string;
  business_email: string;
  terms_and_conditions_url: string;
  privacy_policy_url: string;
}

export enum OrganizationUserRoleType {
  OWNER = "owner",
  MEMBER = "member",
}

export const FoodPreferences: IFoodPreference[] = [
  {
    id: "gluten_intolerant",
    label: "Gluten Intolerant",
  },
  {
    id: "lactose_intolerant",
    label: "Lactose Intolerant",
  },
  {
    id: "vegetarian",
    label: "Vegetarian",
  },
  {
    id: "vegan",
    label: "Vegan",
  },
  {
    id: "nut_allergy",
    label: "Nut Allergy",
  },
  {
    id: "egg_allergy",
    label: "Egg Allergy",
  },
  {
    id: "soy_allergy",
    label: "Soy Allergy",
  },
  {
    id: "shellfish_allergy",
    label: "Shellfish Allergy",
  },
  {
    id: "kosher",
    label: "Kosher",
  },
  {
    id: "halal",
    label: "Halal",
  },
  {
    id: "prefer_meat",
    label: "I Prefer Meat If Available",
  },
  {
    id: "other",
    label: "Other",
  },
];

export type AuthAction =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction;

// TicketReleaseStatistics holds statistics for a specific ticket release.
export interface TicketReleaseStatistics {
  ticket_release_id: number; // The unique ID of the ticket release.
  ticket_release_name: string; // The name of the ticket release.

  // These fields store individual stats for the ticket release.
  ticket_orders: number; // Total number of ticket orders for the release.
  paid_tickets: number; // Number of tickets that have been paid for in this release.
  refunded_tickets: number; // Number of tickets refunded in this release.

  // PayDirect only metrics.
  abandoned_ticket_orders: number; // Number of ticket orders that were abandoned before payment was completed.

  // Only for FCFS and FCFS-Lottery
  reserve_list_length: number; // Number of users on the reserve list for this release.
}

// TicketTypeStatistics holds statistics for individual ticket types within a release.
export interface TicketTypeStatistics {
  ticket_type_id: number; // The unique ID of the ticket type.
  ticket_type_name: string; // The name of the ticket type (e.g., VIP, General Admission).
  total_orders: number; // Total number of orders for this ticket type.
  total_paid: number; // Total number of paid tickets for this ticket type.
  total_refunded: number; // Total number of refunded tickets for this ticket type.
  total_amount: number; // Total revenue generated by this ticket type.
}

// AddOnStatistics holds statistics for each add-on in an event.
export interface AddOnStatistics {
  add_on_id: number; // The unique ID of the add-on.
  add_on_name: string; // The name of the add-on (e.g., drink, merchandise).
  total_selected: number; // The number of times this add-on was selected.
  total_revenue: number; // Total revenue generated from this add-on.
}

export interface HelperValues {
  total_tickets: number;
}

// EventStatisticsResponse contains all aggregated statistics for the event.
export interface EventStatisticsResponse {
  ticket_release_stats: TicketReleaseStatistics[]; // List of statistics per ticket release.
  ticket_type_stats: TicketTypeStatistics[]; // List of statistics per ticket type.
  add_on_stats: AddOnStatistics[]; // List of statistics per add-on.

  // Aggregated statistics for the entire event.
  total_ticket_orders: number; // Total number of ticket orders for the event.
  total_paid_tickets: number; // Total number of paid tickets for the event.
  total_refunded_tickets: number; // Total number of refunded tickets for the event.
  total_revenue: number; // Total revenue generated by the event.
  total_remaining_tickets: number; // Number of tickets remaining (unsold).
  total_payable_tickets: number; // Number of tickets that are payable (not reserved).
  revenue_per_ticket: number; // Average revenue generated per ticket.
  event_capacity_percentage: number; // Percentage of total event capacity that has been sold.
  abandoned_ticket_orders: number; // Number of ticket orders abandoned before payment was completed.
  time_to_payment: number; // Average time taken for customers to complete payment after creating an order (in milliseconds).
  helper_values: HelperValues;
}

export interface IEmailTemplate {
  id: number; // Corresponds to gorm.Model ID
  created_at: string; // Corresponds to gorm.Model CreatedAt
  updated_at: string; // Corresponds to gorm.Model UpdatedAt
  deleted_at?: string | null; // Corresponds to gorm.Model DeletedAt, optional

  name: string; // Template name
  subject: string; // Template subject
  body: string; // Template body (may contain placeholders for dynamic data)
  required_data: Record<string, string>; // JSON for required template data like { "firstName": "string", "eventName": "string" }
}

export interface IEventEmailTemplate extends IEmailTemplate {
  event_id: number; // Foreign key for Event
  event: IEvent; // Event relation
}

export interface IEmailTemplateValues {
  name: string;
  subject: string;
  body: string;
  required_data?: Record<string, string>;
}

// NetworkContact represents the contact information for a network user.
export interface INetworkContact {
  id: number; // Primary key
  network_id: number; // Foreign key to the network
  user_id: string; // Foreign key to the user
  user: IUser; // User relation
  email: string; // Email address
  email_subscription: boolean; // Subscribed to the network
  last_added_reason: string; // Last reason they were added
  last_added_by: string; // Who added them last
  logs: INetworkContactLog[]; // Array of contact logs
}

// NetworkContactLog stores every reason a user was added to a network, along with metadata.
export interface INetworkContactLog {
  id: number; // Primary key
  created_at: string; // Timestamp of creation
  updated_at: string; // Timestamp of last update
  deleted_at?: string; // Timestamp of deletion (optional)
  network_id: number; // Reference to the network
  user_id: string; // Reference to the user
  added_by: string; // Who added the user
  reason: string; // Reason for adding the user
  metadata: INetworkContactLogMetadata; // Metadata for the log
}

// NetworkContactLogMetadata holds additional metadata for network contact logs.
export interface INetworkContactLogMetadata {
  source: string; // e.g., "signup_form", "imported_data"
  version: number; // Version of the data schema
  tags: { [key: string]: string }; // Optional map of key-value pairs
}

export const EventEmailTypes = {
  InviteEmail: "EVENT_INVITE",
  PreEventEmail: "PRE_EVENT",
  PostEventEmail: "POST_EVENT",
};

export interface IChatRequest {
  session_id?: number;
  message: string;
  session_type: IAiChatSessionType;
  organization_id?: number;
  event_id?: number;
}

export type IAiChatSessionType =
  | "event_generation"
  | "event_food_planning"
  | "ticket_sales_overview"
  | "event_page";

export enum AiChatSessionValues {
  EVENT_GENERATION = "event_generation",
  EVENT_FOOD_PLANNING = "event_food_planning",
  TICKET_SALES_OVERVIEW = "ticket_sales_overview",
}

export type AIChatRole = "system" | "user" | "assistant";

export interface IAIChatSession {
  id: number;
  created_at: Date; // or Date
  updated_at: Date; // or Date
  name: string;
  network_id: number;
  network: INetwork;
  user_ug_kth_id: string;
  organization_id?: number;
  organization?: IOrganization;
  messages: IAIChatSessionMessage[];
  is_active: boolean;
  language: string;
}

export interface IAIEventData extends Partial<CompleteEventWorkflowPostReq> {
  prompt_description: string;
  html?: string;
  css?: string;
}

export interface IAIChatSessionMessage {
  id: number;
  created_at: Date; // or Date
  updated_at: Date; // or Date
  session_id: number;
  role: AIChatRole;
  content: string;
  data?: IAIEventData;
  message_type: IAiChatSessionType;
  sent_by?: IUser;
}

export interface AIChatResponse {
  session: IAIChatSession;
}

export interface INewKeyDetails {
  api_key: string;
  secret_key: string;
}

export interface IAPIKey {
  id: number;
  api_key: string;
  description: string;
  permissions: IPermission[];
  revoked: boolean;
  expires_at: Date;
  rotated_at: Date;
  rate_limit_requests: number;
  rate_limit_duration: number;
}

export interface IPermission {
  id: number;
  name: string;
  description: string;
  endpoints: IEndpoint[];
}

export interface IEndpoint {
  id: number;
  path: string;
  method: string;
}

export interface ICreateAPIKeyRequest {
  permissions: string[];
  expires_at: string;
  description: string;
  rate_limit: {
    requests: number;
    duration: string;
  };
}

export enum NetworkAuthProviderType {
  KTH = "kth",
}

// Main NetworkAuthProvider interface
export interface INetworkAuthProvider {
  id: number;
  networks: INetwork[];
  type: NetworkAuthProviderType;
  network_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

// Request/Response shapes for API
export interface INetworkAuthProviderRequest {
  type: NetworkAuthProviderType;
  networks: number[]; // Array of network IDs
}

export interface NetworkAuthProviderResponse extends INetworkAuthProvider {}

export interface IEventMeal {
  id: number;
  name: string;
  description: string;
  tags: string[];
  event_id: number;
  created_at: string;
  updated_at: string;
}

export interface IMealForm {
  name: string;
  description: string;
  tagInput: string;
  tags: string[];
  event_id: number;
}

export interface ITicketAttendee {
  id: number;
  ticket_id: number;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  food_preferences: IUserFoodPreference;
}

export interface IAttendeeForm {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  food_preferences: IAttendeeFoodPreference;
}

export interface IAttendeeFoodPreference {
  preferences: string[];
  additional_info: string;
}

export interface AttendeeFormValues {
  attendees: IAttendeeForm[];
}

export interface IAllowedDomain {
  id: number;
  domain: string;
  event_id: number;
  created_at: string;
  updated_at: string;
}

export interface EventDraft {
  name?: string;
  description?: string;
  date?: string;
  end_date?: string | null;
  location?: string;
  organization_id?: number;
  is_private?: boolean;
  collect_food_preferences?: boolean;
  display_attendee_count?: boolean;
  language?: "en" | "se";
  category?:
    | "event"
    | "workshop"
    | "lecture"
    | "party"
    | "conference"
    | "concert"
    | "other";
}

export interface TicketReleaseDraft {
  name?: string;
  description?: string;
  open?: string;
  close?: string;
  open_window_duration?: number | null;
  method_description?: string | null;
  max_tickets_per_user?: number;
  notification_method?: string;
  cancellation_policy?: "FULL_REFUND" | "NO_REFUND";
  ticket_release_method_id?: 1 | 2 | 3 | 4 | 5;
  is_reserved?: boolean;
  promo_code?: string | null;
  tickets_available?: number;
  save_template?: boolean;
  payment_deadline?: string | null;
  reserve_payment_duration?: string | null;
  allocation_cut_off?: string | null;
  button_text: string;
}

export interface TicketTypeDraft {
  name: string;
  description: string;
  price: number;
}

export interface ValidationRule {
  required?: boolean;
  min_length?: number;
  max_length?: number;
  pattern?: string;
}

export interface QuestionOption {
  value: string;
  label: string;
}

export interface FieldToExtract {
  path: string;
  required?: boolean;
}

export interface NextQuestion {
  id: string;
  text: string;
  type: "text" | "select" | "multiselect" | "boolean";
  options?: QuestionOption[];
  validation?: ValidationRule;
  field_path: string;
  fields_to_extract?: FieldToExtract[];
}

export interface EventFullWorkflowResponse {
  current_state: {
    event?: EventDraft;
    ticket_release?: TicketReleaseDraft;
    ticket_types?: TicketTypeDraft[];
  };
  next_question: NextQuestion;
  completion_percentage: number;
  errors?: Array<{
    field: string;
    message: string;
  }>;
}
