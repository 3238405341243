import { Box } from "@mui/joy";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingOverlay from "../Loading";
import TesseraWrapper from "../wrappers/page_wrapper";
import Markdown from "markdown-to-jsx";
import usePalette from "../../theme/use_palette";

const ReturnPolicy: React.FC = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  const getReturnPolicy = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/legal/return-policy`
      );
      setContent(response.data);
    } catch (error) {
      console.error("Error loading return policy:", error);
      setContent("Error loading return policy");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReturnPolicy();
  }, []);

  const palette = usePalette();

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <TesseraWrapper defaultColors={true}>
      <Box
        sx={{
          mx: 10,
          "& h1": { mt: 4, mb: 2 },
          "& h2": { mt: 3, mb: 2 },
          "& p": { mb: 2 },
          "& ul, & ol": { mb: 2, pl: 4 },
          color: palette.black,
        }}
      >
        <Markdown>{content}</Markdown>
      </Box>
    </TesseraWrapper>
  );
};

export default ReturnPolicy;
