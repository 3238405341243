// ManagerLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import DrawerBoxWrapper from "../../components/wrappers/manager_wrapper";
import MUITesseraWrapper from "../../components/wrappers/page_wrapper_mui";
import { useNetworkDetails } from "../../hooks/manager/network_details_hook";
import AISidebarActivate from "../../components/manager/tessera_ai/siderbar/ai_sidebar_activate";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const ManagerLayout: React.FC = () => {
  const { user: currentUser } = useSelector((state: RootState) => state.user);

  return (
    <MUITesseraWrapper navOptions={{ isDashboard: true }}>
      <DrawerBoxWrapper showManagerDashboard={true}>
        <Outlet /> {/* Renders child routes */}
        {/* <AISidebarActivate
          organizationId={currentUser?.organizations?.[0]?.id ?? undefined}
        /> */}
      </DrawerBoxWrapper>
    </MUITesseraWrapper>
  );
};

export default ManagerLayout;
