// src/dataProvider.ts
import axios from "axios";
import {
  DataProvider,
  GetListParams,
  GetOneParams,
  UpdateParams,
  CreateParams,
  DeleteParams,
  GetManyReferenceParams,
  DeleteResult,
  RaRecord,
  fetchUtils,
} from "ra-core";
import { toast } from "react-toastify";

interface IdParams {
  ids: (string | number)[]; // Allow both strings and numbers
}

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL + "/admin/" ||
    "http://localhost:8080/admin/",
  withCredentials: true,
});

const dataProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams) => {
    const { pagination = { page: 1, perPage: 10 } } = params;
    const { sort = { field: "id", order: "ASC" } } = params;
    const { field, order } = sort;
    const { page, perPage } = pagination;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${resource}?${fetchUtils.queryParameters(query)}`;

    const { data, headers } = await axiosInstance.get(url);

    return {
      data: data.map((item: any) => ({
        ...item,
        id: item.ID !== undefined ? item.ID : item.id,
      })),
      total: parseInt(headers["content-range"].split("/").pop(), 10),
    };
  },
  getOne: async (resource: string, params: GetOneParams) => {
    try {
      const { data } = await axiosInstance.get(`${resource}/${params.id}`);
      const formattedData = {
        ...data,
        id: data.ID !== undefined ? data.ID : data.id,
      };

      return { data: formattedData };
    } catch (error) {
      console.error("Error in getOne:", error);
      throw error;
    }
  },
  getMany: async (resource: string, params: IdParams) => {
    const url = `${resource}?${fetchUtils.queryParameters({
      filter: JSON.stringify({ id: params.ids }),
    })}`;
    const { data } = await axiosInstance.get(url);
    return {
      data: data.map((item: any) => ({ ...item, id: item.ID })),
    };
  },
  getManyReference: async (
    resource: string,
    params: GetManyReferenceParams
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { target, id } = params;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({ [target]: id, ...params.filter }),
    };
    const url = `${resource}?${fetchUtils.queryParameters(query)}`;
    const { data, headers } = await axiosInstance.get(url);
    return {
      data: data.map((item: any) => ({ ...item, id: item.ID })),
      total: parseInt(headers["content-range"].split("/").pop(), 10),
    };
  },
  update: async (resource: string, params: UpdateParams) => {
    const { data } = await axiosInstance.put(
      `/${resource}/${params.id}`,
      params.data
    );
    return {
      data: { ...data, id: data.id },
    };
  },
  updateMany: async (
    resource: string,
    params: { ids: IdParams["ids"]; data: any }
  ) => {
    const promises = params.ids.map((id) =>
      axiosInstance.put(`/${resource}/${id}`, params.data)
    );
    await Promise.all(promises);
    return { data: params.ids };
  },
  create: async (resource: string, params: CreateParams) => {
    try {
      const { data } = await axiosInstance.post(`/${resource}`, params.data);
      return {
        data: { ...data, id: data.ID || data.id },
      };
    } catch (error: any) {
      throw error;
    }
  },
  delete: async <RecordType extends RaRecord>(
    resource: string,
    params: DeleteParams<RecordType>
  ): Promise<DeleteResult<RecordType>> => {
    await axiosInstance.delete(`/${resource}/${params.id}`);
    return {
      data: params.previousData as RecordType,
    };
  },
  deleteMany: async (resource: string, params: IdParams) => {
    const promises = params.ids.map((id) =>
      axiosInstance.delete(`/${resource}/${id}`)
    );
    await Promise.all(promises);
    return { data: params.ids };
  },
};

export default dataProvider;
