import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import axios from "axios";
import ApiRoutes from "../../../../routes/backend_routes";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { customerLoginSuccess } from "../../../../redux/features/authSlice";
import { currentUserRequest } from "../../../../redux/features/userSlice";
import { ApiResponse, postApi } from "../../../../utils/api/api";
import { IUser } from "../../../../types";
import { useIsDarkTheme } from "../../../../theme/use_palette";
import { usePreferredLanguage } from "@uidotdev/usehooks";
import { useSelector } from "react-redux";

const OAuthGoogle: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isDarkTheme = useIsDarkTheme();
  const { language } = useSelector((state: RootState) => state.language);

  const handleLoginSuccess = async (response: CredentialResponse) => {
    const { credential } = response;
    if (!credential) {
      console.error("No credential found in response");
      return;
    }
    try {
      const response: ApiResponse<{
        user: IUser;
        token: string;
      }> = await postApi(
        ApiRoutes.OAUTH_GOOGLE,
        { token: credential },
        true,
        true
      );

      dispatch(customerLoginSuccess({ user: response.data.user }));
      dispatch(currentUserRequest());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoginFailure = () => {};

  return (
    <GoogleLogin
      onSuccess={handleLoginSuccess}
      onError={handleLoginFailure}
      size="large"
      width="300px"
      theme={isDarkTheme ? "filled_black" : "outline"}
      useOneTap={true}
      text="continue_with"
    />
  );
};

export default OAuthGoogle;
