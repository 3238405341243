import { Form, Formik } from "formik";
import {
  ICustomerLoginValues,
  ICustomerSignupValues,
  IGuestCustomer,
  IGuestCustomerForm,
  ITicketRelease,
} from "../../../../types";
import * as Yup from "yup";
import { Box, Divider, FormControl, Link, Stack } from "@mui/joy";
import { FormCheckbox, FormInput } from "../../../forms/input_types";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../../forms/form_labels";
import { StyledErrorMessage } from "../../../forms/messages";
import StyledButton from "../../../buttons/styled_button";
import usePalette from "../../../../theme/use_palette";
import StyledText from "../../../text/styled_text";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import {
  customerLoginRequest,
  customerSignupRequest,
} from "../../../../redux/features/authSlice";
import { useSelector } from "react-redux";
import {
  hasLottery,
  ticketReleaseRequiresAccount,
} from "../../../../utils/manage_event/can_edit_payment_deadline";
import { useTranslation } from "react-i18next";
import CustomerLoginForm from "../../../customer/login_form";
import { createSignupValidationSchema } from "../../../../validation/user/customer_signup_validation";
import CustomerSignupForm from "../../../customer/signup_form";
import OAuthGoogle from "../../../auth/login/oauth/google_login";
import InformationModal from "../../../modal/information";

interface MakeTicketRequestUserDetailsProps {
  accountIsRequired: boolean;
  ticketRelease: ITicketRelease;
  onSignupContinue: (values: ICustomerSignupValues) => void;
  onLoginContinue: () => void;
  forceShowLogin?: boolean;
  forceEmail?: string;
  emailInUseError?: boolean;
  resetEmailError?: () => void;
  errorMessage?: string;
}

const MakeTicketRequestUserDetails: React.FC<
  MakeTicketRequestUserDetailsProps
> = ({
  accountIsRequired,
  ticketRelease,
  onLoginContinue,
  onSignupContinue,
  forceShowLogin = false,
  forceEmail = "",
  emailInUseError = false,
  resetEmailError,
  errorMessage = "",
}) => {
  const dispatch: AppDispatch = useDispatch();
  const palette = usePalette();

  const [hasAccount, setHasAccount] = useState<boolean>(forceShowLogin);

  // Update to clear error when switching modes
  const handleSwitchToSignup = () => {
    if (resetEmailError) resetEmailError();
    setHasAccount(false);
  };

  const onSignup = (values: ICustomerSignupValues) => {
    dispatch(customerSignupRequest(values));

    if (values.is_saved) {
      setHasAccount(true);
    }

    onSignupContinue(values);
  };

  const onLogin = (values: ICustomerLoginValues) => {
    dispatch(customerLoginRequest(values));
  };

  const { t } = useTranslation();

  // Function to get error message
  const getErrorMessage = () => {
    return (
      errorMessage ||
      t("customer.error.email_already_exists", "Email is already in use")
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        py: 1,
      }}
    >
      {/* Account Required Message */}
      {accountIsRequired && (
        <StyledText
          level="h4"
          color={palette.primaryMain}
          fontWeight={600}
          fontSize={20}
          sx={{ mb: 3, textAlign: "center" }}
        >
          {t(
            "event.ticket_release.request_process.account_required_description"
          )}
        </StyledText>
      )}

      {/* Recommended Authentication Method - Google OAuth */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledText
          level="body-md"
          color={palette.textPrimary}
          fontWeight={600}
          fontSize={16}
          sx={{ textAlign: "center", mb: 1 }}
        >
          {t("customer.recommended_sign_in")}
        </StyledText>
        <OAuthGoogle />
      </Box>

      <Divider sx={{ my: 1, width: "100%" }}>
        <StyledText level="body-sm" color={palette.textSecondary} fontSize={14}>
          {t("common.or")}
        </StyledText>
      </Divider>

      {emailInUseError && (
        <Box sx={{ mb: 2, width: "100%" }}>
          <StyledText
            level="body-md"
            color={palette.errorMain}
            fontWeight={500}
            fontSize={15}
            sx={{ textAlign: "center", textWrap: "balance" }}
          >
            {getErrorMessage()}
          </StyledText>
        </Box>
      )}

      {hasAccount ? (
        <>
          <CustomerLoginForm onLogin={onLogin} forceEmail={forceEmail} />

          <StyledText
            level="body-md"
            color={palette.black}
            fontSize={16}
            fontWeight={500}
            onClick={handleSwitchToSignup}
            sx={{ mt: 2, textAlign: "center" }}
          >
            <Link
              href="#"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t(
                "event.ticket_release.request_process.form.button_continue_as_guest"
              )}
            </Link>
          </StyledText>
        </>
      ) : (
        <>
          <CustomerSignupForm
            onSignup={onSignup}
            accountIsRequired={accountIsRequired}
          />

          <Box sx={{ mt: 2, width: "100%", textAlign: "center" }}>
            <StyledText
              level="body-md"
              color={palette.textSecondary}
              fontWeight={400}
              fontSize={15}
            >
              {t(
                "event.ticket_release.request_process.already_have_an_account"
              )}{" "}
              <Link
                href="#"
                onClick={() => {
                  // Don't reset error here as we're switching to login
                  setHasAccount(true);
                }}
                sx={{
                  color: palette.primaryMain,
                  fontWeight: 500,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("form.button_sign_in")}
              </Link>
            </StyledText>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MakeTicketRequestUserDetails;
