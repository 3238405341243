import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import StyledText from "../../text/styled_text";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/joy";
import { useSelector } from "react-redux";
import {
  selectMainColor,
  selectTextColor,
} from "../../../redux/features/managerThemeSlice";
import {
  darkerColor,
  isColorDark,
  lightenColor,
} from "../../../utils/manager/color";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";

interface DrawerListItemProps {
  icon: React.ReactNode;
  text: string;
  navigateTo: string;
  isSelected: boolean; // New prop
  customBackgroundColor?: string;
  openInNewTab?: boolean;
  endDecorator?: React.ReactNode;
}

const DrawerListItem: React.FC<DrawerListItemProps> = ({
  icon,
  text,
  navigateTo,
  isSelected, // New prop
  customBackgroundColor,
  openInNewTab = false,
  endDecorator,
}) => {
  const navigate = useNavigate();
  const mainColor = useSelector(selectMainColor);
  const isDarkTheme = useIsDarkTheme();
  const palette = usePalette();
  const textColor = palette.black;

  let selectedBackgroundColor;
  const newColor = isColorDark(mainColor)
    ? `linear-gradient(to right, ${lightenColor(mainColor, 0.2)}, ${mainColor})`
    : `linear-gradient(to right, ${darkerColor(mainColor, 0.8)}, ${mainColor})`;

  selectedBackgroundColor = newColor;

  let displayBackgroundColor = customBackgroundColor || "transparent";

  if (isSelected) {
    displayBackgroundColor = isDarkTheme
      ? darkerColor(palette.ultraVioletExtraLight, 0.4)
      : lightenColor(palette.ultraVioletExtraLight, 0.5);
  }

  const handleNavigate = () => {
    if (navigateTo.startsWith("http")) {
      window.open(navigateTo, "_blank");
      return;
    }

    if (openInNewTab) {
      window.open(navigateTo, "_blank");
      return;
    }

    navigate(navigateTo);
  };

  return (
    <ListItem
      disablePadding
      sx={{
        height: 42,
      }}
    >
      <ListItemButton
        onClick={handleNavigate}
        sx={{
          background: displayBackgroundColor,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: selectedBackgroundColor,
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <ListItemIcon sx={{ color: textColor }}>{icon}</ListItemIcon>
          <ListItemText>
            <StyledText
              level="body-md"
              fontSize={16}
              color={textColor}
              forceTextPointer={true}
              fontWeight={600}
              endDecorator={endDecorator}
              sx={{
                m: 0,
                p: 0,
              }}
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </StyledText>
          </ListItemText>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerListItem;
