import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/joy";
// @ts-ignore
import FOG from "vanta/dist/vanta.fog.min";
import * as THREE from "three";
import { lightPaletteExported } from "../../theme/theme_context";
import { lightenColor } from "../../utils/manager/color";
import usePalette, { useIsDarkTheme } from "../../theme/use_palette";

interface VantaBackgroundProps {
  customSettings?: {
    mouseControls?: boolean;
    touchControls?: boolean;
    gyroControls?: boolean;
    minHeight?: number;
    minWidth?: number;
    blurFactor?: number;
    speed?: number;
  };
}

const VantaBackground: React.FC<VantaBackgroundProps> = ({
  customSettings,
}) => {
  const [vantaEffect, setVantaEffect] = useState<any>(null);
  const vantaRef = useRef<HTMLDivElement>(null);
  const isDarkMode = useIsDarkTheme();
  const palette = usePalette();

  useEffect(() => {
    if (vantaEffect) {
      vantaEffect.destroy();
    }

    setVantaEffect(
      FOG({
        el: vantaRef.current,
        THREE: THREE,
        mouseControls: customSettings?.mouseControls ?? true,
        touchControls: customSettings?.touchControls ?? true,
        gyroControls: customSettings?.gyroControls ?? false,
        minHeight: customSettings?.minHeight ?? 200.0,
        minWidth: customSettings?.minWidth ?? 200.0,
        baseColor: palette.offWhite,
        blurFactor: customSettings?.blurFactor ?? 0.6,
        speed: customSettings?.speed ?? 1.5,
        highlightColor: isDarkMode
          ? 0x22223b
          : lightenColor(lightPaletteExported.ultraVioletExtraLight, 0.5),
        midtoneColor: isDarkMode
          ? lightPaletteExported.ultraVioletExtraLight
          : 0xe2d9d4,
      })
    );

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [isDarkMode, customSettings, palette]);

  return (
    <Box
      ref={vantaRef}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        backgroundColor: palette.offWhite,
      }}
    />
  );
};

export default VantaBackground;
