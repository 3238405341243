import React, { lazy } from "react";

export const MainPage = React.lazy(() => import("../pages/main"));
export const ProfilePage = React.lazy(() => import("../pages/profile"));
export const EditEventAddTicketReleasePage = React.lazy(
  () => import("../pages/event/edit/add_ticket_release")
);
export const EditTicketTypes = React.lazy(
  () => import("../pages/event/edit/edit_ticket_types")
);

export const CreateOrganizationPage = React.lazy(
  () => import("../pages/organization/create")
);
export const ManageEventPage = React.lazy(
  () => import("../pages/event/manage")
);
export const TicketScannerPage = React.lazy(
  () => import("../pages/event/ticket_scanner")
);
export const EventEconomyPage = React.lazy(
  () => import("../pages/event/economy")
);
export const EditTicketReleaseAddonsPage = React.lazy(
  () => import("../pages/event/edit/edit_addons")
);
export const ManageEventTicketReleasesPage = React.lazy(
  () => import("../pages/event/manage/ticket_releases")
);
export const ManageEventTicketsPage = React.lazy(
  () => import("../pages/event/manage/tickets")
);
export const ManageEventFormResponsesPage = React.lazy(
  () => import("../pages/event/manage/ticket_form_response_list_page")
);
export const FourOFour404 = React.lazy(() => import("../pages/errors/404"));

export const EventDetail = React.lazy(
  () => import("../pages/event/detail/detail")
);

export const EventDetailLandingPage = React.lazy(
  () => import("../pages/event/detail/detail_landing_page")
);

export const EventsPage = React.lazy(() => import("../pages/event"));

export const CreateEventPage = React.lazy(
  () => import("../pages/event/advanced_create")
);

export const EditEventPage = React.lazy(
  () => import("../pages/event/edit/edit_event")
);

export const LoginPage = React.lazy(() => import("../pages/user_login"));
export const ProtectedRoute = React.lazy(
  () => import("../components/login/PrivateRoute")
);
export const Logout = React.lazy(() => import("../components/user/logout"));

export const HandleLoginCallback = React.lazy(
  () => import("../pages/login/callback")
);
export const ExternalVerifyEmail = React.lazy(
  () => import("../pages/user_login/verify-email")
);
export const ContactPage = React.lazy(() => import("../pages/contact"));

export const ForgotPassword = React.lazy(
  () => import("../pages/user_login/forgot_password")
);
export const PasswordReset = React.lazy(
  () => import("../pages/user_login/password_reset")
);
export const SendOut = React.lazy(
  () => import("../pages/event/send_out/new_send_out")
);

export const PrivacyPolicy = React.lazy(
  () => import("../components/legal/privacy")
);

export const SettingsFinancialPage = React.lazy(
  () => import("../pages/event/settings/finanical_event_settings")
);

export const DomainEventSettings = React.lazy(
  () => import("../pages/event/settings/domain_event_settings")
);

export const EventSendOutsPage = React.lazy(
  () => import("../pages/event/send_out/list_send_out")
);

export const ManageSendOutList = React.lazy(
  () => import("../pages/event/send_out/list_send_out")
);

export const EditEventTicketReleasesPage = React.lazy(
  () => import("../pages/event/edit/edit_ticket_releases")
);

export const EditEventFormPage = React.lazy(
  () => import("../pages/event/edit/edit_event_form")
);

export const PricingPage = React.lazy(() => import("../pages/pricing"));

export const PostLoginPage = React.lazy(
  () => import("../pages/manager/post_login_page")
);

export const BecomeAManagerPage = React.lazy(
  () => import("../pages/manager/become_a_manager")
);

export const ManagerPage = React.lazy(() => import("../pages/manager"));

export const ManagerTeamsPage = React.lazy(
  () => import("../pages/manager/teams")
);

export const ManagerSetupPage = React.lazy(
  () => import("../pages/manager/setup")
);

export const EditLandingPageSettingsPage = React.lazy(
  () => import("../pages/event/edit/edit_landing_page")
);

export const LandingPageEditorPage = React.lazy(
  () => import("../pages/event/edit/edit_landing_page/edit_page")
);

export const NetworkGeneralSettingsPage = React.lazy(
  () => import("../pages/manager/settings/general_settings")
);

export const NetworkUsersSettingsPage = React.lazy(
  () => import("../pages/manager/settings/user_settings")
);

export const NetworkSocialMediaSettings = React.lazy(
  () => import("../pages/manager/settings/social_media_settings")
);

export const AcceptNetworkInvitePage = React.lazy(
  () => import("../pages/network/accept_invite")
);

export const ManagerFinancialSettingsPage = React.lazy(
  () => import("../pages/manager/settings/finanical_settings")
);

export const FAQPage = React.lazy(() => import("../pages/faq"));

export const NetworkCustomizationSettingsPage = React.lazy(
  () => import("../pages/manager/settings/customization_settings")
);

export const ProductPage = React.lazy(() => import("../pages/product/main"));

export const ProductTicketDistributionPage = React.lazy(
  () => import("../pages/product/ticket_distribution")
);

export const ProductEventManagementPage = React.lazy(
  () => import("../pages/product/event_management")
);

export const ProductEventPagesPage = React.lazy(
  () => import("../pages/product/event_pages")
);

export const ProductTeamManagementPage = React.lazy(
  () => import("../pages/product/team_management")
);

export const ProductBETAProgramPage = React.lazy(
  () => import("../pages/product/beta_program")
);

export const ProductManagerOnboardingPage = React.lazy(
  () => import("../pages/product/manager_onboarding")
);

export const ProductEventFormsPage = React.lazy(
  () => import("../pages/product/event_forms")
);

export const ProductPaymentsPage = React.lazy(
  () => import("../pages/product/payment_process")
);

export const ProductDataSecurityPage = React.lazy(
  () => import("../pages/product/data_privacy")
);

export const EventEmailTemplatesPage = React.lazy(
  () => import("../pages/event/email/templates/manage_email_templates")
);

export const NetworkSubscriptionSettingsPage = React.lazy(
  () => import("../pages/manager/settings/subscription_settings")
);

export const NetworkContactDatabasePage = React.lazy(
  () => import("../pages/manager/contact_database")
);

export const NetworkBusinessPage = React.lazy(
  () => import("../pages/network/business/business_page")
);

export const UnsubscribePage = React.lazy(
  () => import("../pages/contact/unsubsribe")
);

export const ViewOrganizationPage = React.lazy(
  () => import("../pages/manager/teams/view")
);

export const NetworkAIAssistantPage = React.lazy(
  () => import("../pages/manager/tessera_ai")
);

export const NetworkApiKeysSettingsPage = React.lazy(
  () => import("../pages/manager/settings/api_settings")
);

export const EventToolsSeatingPlan = React.lazy(
  () => import("../pages/event/tools/seating_plan_editor")
);

export const LoginAlternativesPage = React.lazy(
  () => import("../pages/user_login/alternatives")
);

export const MealManagementPage = React.lazy(
  () => import("../pages/event/tools/meal_management_page")
);

export const EmbedEventPage = React.lazy(
  () => import("../pages/event/tools/embed_event_page")
);

// Solutions pages
export const SolutionsMainPage = lazy(() => import("../pages/solutions/main"));
export const InPersonEventsPage = lazy(
  () => import("../pages/solutions/in_person_events")
);
export const UniversityEventsPage = lazy(
  () => import("../pages/solutions/university_events")
);

export { default as ComplexTeamsPage } from "../pages/solutions/complex_teams";

export const AICreateEventPage = React.lazy(
  () => import("../pages/manager/ai_create")
);

export const EasyEventSetupPage = lazy(
  () => import("../pages/solutions/easy_event_setup")
);

export const EventDraftPage = lazy(
  () => import("../pages/event/draft/event_draft_page")
);

export const EventCreateSelectPage = lazy(
  () => import("../pages/manager/create/event_create_select")
);
