import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  customerSignupRequest,
  resetGuestCustomer,
  resetLoginSuccess,
  resetSignupSuccess,
} from "../../../../redux/features/authSlice";
import {
  getGuestCustomerRequest,
  resetGuestCustomer as resetGuestCustomerSlice,
  resetRequestSuccess,
} from "../../../../redux/features/guestCustomerSlice";
import {
  resetError,
  resetNewlyCreatedTicketOrder,
  resetPostSuccess,
} from "../../../../redux/features/ticketOrderSlice";
import { fetchFormFieldsRequest } from "../../../../redux/features/formFieldsSlice";

import MakeTicketRequestUserDetails from "./make_ticket_request_user_details";
import AttendeeDetailsForm from "./attendee_details_form";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";

import {
  ticketReleaseRequiresAccount,
  requiresUpfrontPayment,
} from "../../../../utils/manage_event/can_edit_payment_deadline";
import { handlePay } from "../../../../handlers/paymentHandler";
import { ConstructNormalRoute } from "../../../../utils/subdomain/subdomain_utils";
import { updateAttendees } from "../../../../redux/sagas/axios_calls/ticket/attendee_service";

import { AppDispatch, RootState } from "../../../../store";
import {
  AttendeeFormValues,
  ICustomerSignupValues,
  IEvent,
  ITicketOrder,
  ITicketRelease,
} from "../../../../types";

interface MakeTicketOrderWorkflowProps {
  ticketRelease: ITicketRelease;
  onSubmitGuestTicketOrder: () => void;
  onSubmitTicketOrder: () => void;
  onClose: () => void;
  payDirect?: boolean;
}

interface TicketData {
  ticket_type_id: number;
  addons: {
    id: number;
    quantity: number;
  }[];
}

const Steps = {
  USER_DETAILS: 0, // Collect user details
  CREATING_TICKET_ORDER: 1, // Creating ticket order
  FETCHING_TICKET_ORDER: 2, // Fetching ticket order
  LOADING_TICKET_ORDER: 3, // Loading ticket order
  ATTENDEE_DETAILS: 4, // Collect attendee details
  SAVING_ATTENDEES: 5, // Saving attendee details
  REDIRECTING_TO_PAYMENT: 6, // Redirecting to payment page
};

const MakeTicketOrderWorkflow: React.FC<MakeTicketOrderWorkflowProps> = ({
  ticketRelease,
  onSubmitTicketOrder,
  onSubmitGuestTicketOrder,
  onClose,
  payDirect = false,
}) => {
  // Hooks
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const palette = usePalette();
  const { refID } = useParams<{ refID: string }>();

  // Redux state selectors
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const {
    customerSignupSuccess,
    customerLoginSuccess,
    isLoggedIn,
    guestCustomer,
    loading,
    error: authError,
  } = useSelector((state: RootState) => state.auth);
  const { guestCustomer: fetchedGuestCustomer, create_ticket_request_success } =
    useSelector((state: RootState) => state.guestCustomer);
  const { formFields } = useSelector((state: RootState) => state.formFields);
  const {
    postSuccess,
    error: postError,
    newlyCreatedTicketOrder,
  } = useSelector((state: RootState) => state.ticketOrder);
  const { event } = useSelector(
    (state: RootState) => state.customerViewEvent
  ) as {
    event: IEvent | null;
  };

  // State variables
  // Flow control state
  const [activeStep, setActiveStep] = useState(Steps.USER_DETAILS);
  const [accountIsRequired, setAccountIsRequired] = useState<boolean>(false);

  const [signupIsSaved, setSignupIsSaved] = useState<boolean>(false);
  const [emailInUseError, setEmailInUseError] = useState<boolean>(false);

  // User information
  const [userEmail, setUserEmail] = useState<string>("");
  const [forceLogin, setForceLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [ticketOrder, setTicketOrder] = useState<ITicketOrder | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  // Other state variables
  const [eventRefID, setEventRefID] = useState<string | null>(null);

  // Add a separate function to reset email error state
  const resetEmailError = () => {
    setEmailInUseError(false);
    setErrorMessage("");
  };

  // Reset function to clear states
  const reset = () => {
    dispatch(resetSignupSuccess());
    dispatch(resetLoginSuccess());
    dispatch(resetGuestCustomer());
    dispatch(resetRequestSuccess());
    dispatch(resetError());
    dispatch(resetPostSuccess());
    dispatch(resetNewlyCreatedTicketOrder());

    setActiveStep(Steps.USER_DETAILS);
    setForceLogin(false);
    setEmailInUseError(false);
    setErrorMessage("");
  };

  // Effect: Handle authentication error
  useEffect(() => {
    if (authError) {
      // Extract error message from auth error if possible
      let extractedError = authError;
      try {
        // Check if error contains JSON data
        if (typeof authError === "string" && authError.includes("data")) {
          // Try to parse out the actual error message from the response
          const match = authError.match(/"error":"([^"]+)"/);
          if (match && match[1]) {
            extractedError = match[1];
          }
        }
      } catch (e) {
        // If parsing fails, use the original error
        extractedError = authError;
      }

      // During signup, most 400 errors are likely email already in use
      // Check if it contains email-related phrases or is a 400 error
      if (
        authError.toLowerCase().includes("email") ||
        authError.toLowerCase().includes("400") ||
        authError.includes("Request failed with status code 400")
      ) {
        // Set state to show login form instead
        setForceLogin(true);
        setEmailInUseError(true);
        setErrorMessage(extractedError);
        // Don't close the modal
      } else {
        // For other errors, reset and close as before
        reset();
        onClose();
      }
    }
  }, [authError]);

  // Effect: Set event reference ID
  useEffect(() => {
    if (refID) {
      setEventRefID(refID);
    } else if (event) {
      setEventRefID(event.reference_id!);
    }
  }, [refID, event]);

  // Effect: Fetch form fields when ticketRelease changes
  useEffect(() => {
    if (ticketRelease?.event_id) {
      dispatch(fetchFormFieldsRequest({ eventId: ticketRelease.event_id }));
    }
  }, [dispatch, ticketRelease?.event_id]);

  // Effect: Determine if account is required
  useEffect(() => {
    const requiresAccount = ticketReleaseRequiresAccount(
      ticketRelease?.ticket_release_method_detail.ticket_release_method!
    );
    setAccountIsRequired(requiresAccount);
  }, [ticketRelease]);

  // Effect: Reset states on component mount
  useEffect(() => {
    reset();
  }, []);

  // Effect: Handle guest customer ticket request
  useEffect(() => {
    if (guestCustomer !== null && !loading) {
      onSubmitGuestTicketOrder();
    }
  }, [guestCustomer, loading]);

  // Effect: Handle ticket request success
  useEffect(() => {
    if (create_ticket_request_success) {
      dispatch(resetRequestSuccess());
      handleNext();
    }
  }, [create_ticket_request_success]);

  // Effect: Handle ticket order post success
  useEffect(() => {
    if (postSuccess) {
      handleNext();
      dispatch(resetPostSuccess());
    }
  }, [postSuccess]);

  // Effect: Submit ticket order if logged in
  useEffect(() => {
    if (isLoggedIn && !loading) {
      onSubmitTicketOrder();
    }
  }, [isLoggedIn, loading]);

  // Effect: Handle post error
  useEffect(() => {
    if (postError) {
      dispatch(resetError());
      dispatch(resetPostSuccess());
      handleClose();
    }
  }, [postError]);

  // Effect: Handle customer signup and login success
  useEffect(() => {
    if (customerSignupSuccess) {
      dispatch(resetSignupSuccess());
      handleNext();
    }
    if (customerLoginSuccess) {
      dispatch(resetLoginSuccess());
    }
  }, [customerSignupSuccess, customerLoginSuccess]);

  // Effect: Handle active step changes
  useEffect(() => {
    handleActiveStepChange();
  }, [activeStep]);

  // Function to handle changes when activeStep updates
  const handleActiveStepChange = () => {
    const isGuestCustomer = guestCustomer !== null;
    const isPayDirect = requiresUpfrontPayment(
      ticketRelease?.ticket_release_method_detail.ticket_release_method
    );

    switch (activeStep) {
      case Steps.CREATING_TICKET_ORDER:
        setTimeout(handleNext, 200);
        break;

      case Steps.FETCHING_TICKET_ORDER:
        setTimeout(handleNext, 1000);
        if (isGuestCustomer) {
          dispatch(
            getGuestCustomerRequest({
              ugkthid: guestCustomer?.id!,
              request_token: guestCustomer?.request_token!,
            })
          );
        } else {
          dispatch(resetGuestCustomer());
        }
        dispatch(resetSignupSuccess());
        dispatch(resetLoginSuccess());
        break;

      case Steps.LOADING_TICKET_ORDER:
        const user = isLoggedIn ? currentUser : fetchedGuestCustomer;
        setTicketOrder(
          isGuestCustomer
            ? fetchedGuestCustomer?.ticket_orders?.[0]!
            : newlyCreatedTicketOrder!
        );
        if (user) {
          setCurrentUserInfo({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone_number: user.phone_number || "",
          });
        }
        if (!isGuestCustomer) {
          setTimeout(handleNext, 0);
        }
        break;

      case Steps.ATTENDEE_DETAILS:
        break;

      case Steps.SAVING_ATTENDEES:
        if (payDirect && newlyCreatedTicketOrder) {
          handlePay(
            newlyCreatedTicketOrder,
            eventRefID!,
            !isLoggedIn,
            !isLoggedIn ? guestCustomer : null
          );
          dispatch(resetNewlyCreatedTicketOrder());
          return;
        }
        setTimeout(() => {
          if (isGuestCustomer && guestCustomer) {
            window.location.assign(
              ConstructNormalRoute(
                `/events/${eventRefID!}/guest/${
                  guestCustomer.id
                }?request_token=${
                  guestCustomer.request_token
                }&pay_direct=${payDirect}`
              )
            );
          } else if (!isGuestCustomer && newlyCreatedTicketOrder) {
            window.location.assign(
              ConstructNormalRoute(
                `/profile?tab=tickets&ticket_order_id=${newlyCreatedTicketOrder.id}&created=true`
              )
            );
          }
        }, 1000);
        break;

      default:
        break;
    }
  };

  // Handlers
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSignupContinue = (values: ICustomerSignupValues) => {
    setUserEmail(values.email);
    setSignupIsSaved(values.is_saved || false);
  };

  const handleLoginContinue = () => {
    handleNext();
  };

  const handleClose = () => {
    dispatch(resetSignupSuccess());
    dispatch(resetLoginSuccess());
    setForceLogin(false);
    setEmailInUseError(false);
    onClose();
  };

  // Handle attendees submit
  const handleAttendeesSubmit = async (
    values: AttendeeFormValues,
    ticket_order_id: number,
    terms_agreed: boolean
  ) => {
    try {
      const response = await updateAttendees(
        event?.id!,
        {
          tickets: ticketOrder!.tickets!.map((ticket, index) => ({
            ticket_id: ticket.id,
            attendee: values.attendees[index],
          })),
          ticket_order_id,
          terms_agreed,
        },
        guestCustomer
      );

      if (response.status === "success") {
        handleNext();
      }
    } catch (error) {
      toast.error("Failed to update attendees");
    }
  };

  // Effect: Reset guest customer on component mount
  useEffect(() => {
    dispatch(resetGuestCustomerSlice());
  }, [dispatch]);

  // Render content based on active step
  const renderStepContent = () => {
    if (!ticketRelease)
      return <LoadingScreen message={t("event.loading.event")} />;

    const isPayDirect = requiresUpfrontPayment(
      ticketRelease?.ticket_release_method_detail.ticket_release_method
    );

    switch (activeStep) {
      case Steps.USER_DETAILS:
        return (
          <MakeTicketRequestUserDetails
            accountIsRequired={accountIsRequired}
            ticketRelease={ticketRelease}
            onSignupContinue={handleSignupContinue}
            onLoginContinue={handleLoginContinue}
            forceShowLogin={forceLogin}
            forceEmail={forceLogin ? userEmail : ""}
            emailInUseError={emailInUseError}
            resetEmailError={resetEmailError}
            errorMessage={errorMessage}
          />
        );

      case Steps.CREATING_TICKET_ORDER:
        return (
          <LoadingScreen
            message={
              isPayDirect
                ? t("event.loading.create_order")
                : t("event.loading.create_request")
            }
          />
        );

      case Steps.FETCHING_TICKET_ORDER:
        return (
          <LoadingScreen
            message={
              isPayDirect
                ? t("event.loading.fetch_order")
                : t("event.loading.fetch_request")
            }
          />
        );

      case Steps.LOADING_TICKET_ORDER:
        return (
          <LoadingScreen
            message={
              isPayDirect
                ? t("event.loading.load_order")
                : t("event.loading.load_request")
            }
          />
        );

      case Steps.ATTENDEE_DETAILS:
        return ticketOrder ? (
          <AttendeeDetailsForm
            ticketOrder={ticketOrder}
            currentUser={currentUserInfo}
            onSubmit={handleAttendeesSubmit}
            eventID={ticketRelease.event_id}
            formFields={formFields}
            showFormFields={formFields.length > 0}
            event={event!}
          />
        ) : (
          <LoadingScreen message={t("event.loading.just_a_moment")} />
        );

      case Steps.SAVING_ATTENDEES:
        return (
          <LoadingScreen
            message={
              isPayDirect
                ? totalAmount === 0
                  ? t("event.loading.registering_ticket")
                  : t("event.loading.redirect_payment")
                : t("event.loading.saving_request")
            }
          />
        );

      case Steps.REDIRECTING_TO_PAYMENT:
        return (
          <LoadingScreen
            message={
              isPayDirect
                ? totalAmount === 0
                  ? t("event.loading.registering_ticket")
                  : t("event.loading.redirect_payment")
                : t("event.loading.saving_request")
            }
          />
        );

      default:
        return null;
    }
  };

  // LoadingScreen Component
  const LoadingScreen = ({ message }: { message: string }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "300px",
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="success" size="lg" variant="plain" />
        <StyledText color={palette.primaryMain} level="h2" fontSize={28}>
          {message}
        </StyledText>
      </Stack>
    </Box>
  );

  // Calculate total amount including addons
  const totalAmount =
    ticketOrder?.tickets?.reduce((total: number, ticket) => {
      const ticketType = ticketRelease.ticket_types?.find(
        (t) => t.id === ticket.ticket_type_id
      );
      const ticketPrice = ticketType?.price || 0;
      const addonsPrice =
        ticket.ticket_add_ons?.reduce((sum: number, addon) => {
          const addonInfo = ticketRelease.add_ons?.find(
            (a) => a.id === addon.add_on_id
          );
          return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
        }, 0) || 0;
      return total + ticketPrice + addonsPrice;
    }, 0) || 0;

  return <Box>{renderStepContent()}</Box>;
};

export default MakeTicketOrderWorkflow;
