// src/admin/components/networkMerchant/network_merchant_edit_create.tsx
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  TextField,
} from "react-admin";
import { applicationStatusChoices } from "./network_merchant_list";

const NetworkMerchantForm = () => (
  <SimpleForm>
    <TextInput source="network_id" validate={required()} />
    <TextInput source="merchant_id" validate={required()} />
    <TextInput source="application_id" />
    <SelectInput
      source="application_status"
      choices={applicationStatusChoices}
      validate={required()}
    />
    <TextInput source="web_kyb_url" />
    <TextInput source="store_id" />
    <SelectInput
      source="fee_transaction_plan"
      choices={[
        { id: "TSSR_STANDARD", name: "Standard" },
        { id: "TSSR_NON_PROFIT", name: "Non-Profit" },
      ]}
      validate={required()}
    />
  </SimpleForm>
);

export const NetworkMerchantEdit = () => (
  <Edit>
    <NetworkMerchantForm />
  </Edit>
);

export const NetworkMerchantCreate = () => (
  <Create>
    <NetworkMerchantForm />
  </Create>
);
