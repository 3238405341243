import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Chip,
  useColorScheme,
  Button,
  IconButton,
} from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import {
  selectAccentColor,
  selectMainColor,
  selectTextColor,
} from "../../redux/features/managerThemeSlice";
import NavLinks from "./nav_links";
import AuthButtons from "./auth_buttons";
import LanguageSelector from "./language_selector";
import StyledText from "../text/styled_text";
import MobileNavigationBar from "./mobile-nav";
import { adjustTransparency } from "../../utils/manager/color";
import { INavigationBarOptions, INetwork } from "../../types";
import { Style } from "@mui/icons-material";
import {
  ConstructNormalRoute,
  IsUsingSubdomain,
  RemoveSubdomainFromRoute,
} from "../../utils/subdomain/subdomain_utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/def";
import usePalette, { useIsDarkTheme } from "../../theme/use_palette";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { ThemeToggleButton } from "./theme_toggle_button";
import { AppDispatch, RootState } from "../../store";
import { getNetworkRequest } from "../../redux/features/manager/networkSlice";
import { useDispatch } from "react-redux";
import TesseraLogo from "../../assets/logos/tesera-logo-white-png-clip.png";
import TesseraLogoText from "../../assets/logos/tessera-logo-text.png";

interface NavigationBarProps {
  options?: INavigationBarOptions;
  shouldUseDefaultColor?: boolean;
  isJoyTheme?: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  options,
  shouldUseDefaultColor = false,
  isJoyTheme = true,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery("(max-width:1000px)");
  const palette = usePalette();
  const dispatch: AppDispatch = useDispatch();
  const { network } = useSelector((state: RootState) => state.network);
  const darkMode = useIsDarkTheme();

  const netMainColor = useSelector(selectMainColor);
  const netTextColor = useSelector(selectTextColor);

  const mainColor = shouldUseDefaultColor ? palette.primaryMain : netMainColor;
  const textColor = shouldUseDefaultColor ? palette.white : netTextColor;

  const isEventPage = options?.isEventPage ?? false;
  const isDashboard = options?.isDashboard ?? false; // New isDashboard flag
  const isBusinessPage = options?.isBusinessPage ?? false;
  const usingSubdomain = IsUsingSubdomain();
  const navigate = useNavigate();

  const backgroundColor = isDashboard
    ? "transparent" // Make background transparent if on the dashboard
    : isEventPage
    ? adjustTransparency(palette.white, 0.15)
    : isBusinessPage
    ? adjustTransparency(palette.white, 0.3)
    : mainColor;

  if (isScreenSmall) {
    return <MobileNavigationBar />;
  }

  return (
    <Box
      sx={{
        bgcolor: adjustTransparency(backgroundColor, 0.75),
        width: "100%",
        height: isEventPage || isBusinessPage ? "auto" : "64px",
        position: isEventPage || isDashboard ? "absolute" : "fixed",
        top: 0,
        zIndex: 1000,
        backdropFilter: !isDashboard ? "blur(5px)" : undefined,
        borderRadius: "0 0 8px 8px",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent={
          isEventPage || isBusinessPage ? "center" : "space-between"
        }
        sx={{
          padding: isEventPage || isBusinessPage ? "8px" : "16px",
          marginLeft: "16px",
          marginRight: "8px",
        }}
      >
        {/* Left-aligned logo */}
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {isEventPage || isBusinessPage ? (
              <Stack
                direction="column"
                spacing={-1.2}
                sx={{
                  pointer: "cursor",
                }}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    // Command (Mac) or Ctrl (Windows/Linux) pressed
                    window.open(ROUTES.MAIN, "_blank");
                  } else {
                    window.location.assign(ROUTES.MAIN);
                  }
                }}
              >
                <StyledText
                  color={palette.black}
                  level="h4"
                  fontSize={12}
                  forceTextPointer
                  sx={{
                    textDecoration: "none",
                    textAlign: "center",
                    opacity: 0.6,
                  }}
                >
                  Powered by
                </StyledText>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid>
                    <img
                      src={TesseraLogoText}
                      alt="Tessera Logo"
                      style={{
                        width: "100px",
                        filter: darkMode ? "invert(1)" : "invert(0)",
                        cursor: "pointer", // Add cursor pointer
                        transition: "all 0.2s ease",
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            ) : !isDashboard ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  pointer: "cursor",
                }}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) {
                    // Command (Mac) or Ctrl (Windows/Linux) pressed
                    window.open(ROUTES.MAIN, "_blank");
                  } else {
                    window.location.assign(ROUTES.MAIN);
                  }
                }}
              >
                <img
                  src={TesseraLogo}
                  alt="Tessera Logo"
                  style={{
                    width: "130px",
                    filter: !darkMode ? "invert(1)" : "invert(0)",
                    transition: "all 0.2s ease",
                    cursor: "pointer", // Add cursor pointer
                  }}
                />
                <Chip
                  sx={{
                    backgroundColor: palette.primaryDark,
                    transform: "translateY(6%)",
                  }}
                >
                  <StyledText
                    level="body-sm"
                    fontSize={14}
                    fontWeight={800}
                    color={palette.offWhite}
                  >
                    BETA
                  </StyledText>
                </Chip>
              </Stack>
            ) : (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "16px",
                  }}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      // Command (Mac) or Ctrl (Windows/Linux) pressed
                      window.open(ROUTES.MAIN, "_blank");
                    } else {
                      window.location.assign(ROUTES.MAIN);
                    }
                  }}
                >
                  <img
                    src={TesseraLogoText}
                    alt="Tessera Logo"
                    style={{
                      width: "110px",
                      opacity: 0.2,
                      filter: darkMode ? "invert(1)" : "invert(0)",
                      transition: "all 0.2s ease",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </>
            )}
          </Stack>
          {/* Conditionally hide NavLinks when on the dashboard */}
          {!isEventPage && !isDashboard && !isBusinessPage && (
            <Box>
              <NavLinks textColor={textColor} />
            </Box>
          )}
        </Stack>

        {/* Right-aligned language selector and auth buttons */}
        {!isEventPage && !isBusinessPage ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <LanguageSelector isEventPage={isEventPage} />
            <ThemeToggleButton />
            <AuthButtons
              mainColor={mainColor}
              textColor={palette.white}
              showLogin={options?.showLogin ?? true}
            />
          </Stack>
        ) : (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px",
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <LanguageSelector isEventPage={isEventPage} />
              <ThemeToggleButton />
              <AuthButtons
                mainColor={mainColor}
                textColor={palette.white}
                isEventPage={isEventPage}
                showLogin={options?.showLogin ?? true}
                showBookDemo={!isEventPage}
              />
            </Stack>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default NavigationBar;
